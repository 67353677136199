import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formateOelPipe',
  pure: false
})
export class FormatOelPipe implements PipeTransform {

  constructor() {

  }
  transform(value: any) {
      if(value!==undefined)
      value = value.toLowerCase();
      switch (value) {
          case 'o':
              return "Oui";

          case 'n':
              return "Non";
          case 'a':
              return "Australiennes";

          default:
              return "Aucunes"


      }

  }
  formatAmount(montant: string): any {
      var size = montant.length;
      var toreturn = '';
      if (size < 4) {
          toreturn = montant;
      } else if (size == 4) {
          toreturn = this.setseparator(1, montant);
      } else if (size == 5) {
          toreturn = this.setseparator(2, montant);
      } else if (size == 6) {
          toreturn = this.setseparator(3, montant);
      } else if (size == 7) {
          toreturn = this.setseparator(5, (this.setseparator(1, montant)));
      } else if (size == 8) {
          toreturn = this.setseparator(6, (this.setseparator(2, montant)));
      } else if (size == 9) {
          toreturn = this.setseparator(7, (this.setseparator(3, montant)));
      }
      return toreturn;
  }
  setseparator(location, amount): any {
      var index = location
      var seperator = ",";
      var finalString = amount;
      var output = [amount.slice(0, location), seperator, amount.slice(location)].join('');
      return output;
  }

}

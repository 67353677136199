import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService} from '@ngx-translate/core'
@Injectable()
export class Alerts {

  public alertPresented: any;
  public alert: any;
  constructor(public alertCtrl: AlertController, private translate: TranslateService) {
    this.alertPresented = false
    this.translate.currentLang = 'fr'
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
  }
  dismiss() {
    let vm = this

    if (vm.alertPresented) {
      vm.alert.dismiss();
    }
  }
  async present(title, subTitle) {
    const vm = this;

    vm.alertPresented = true;
    this.alert = await vm.alertCtrl.create({
      header: this.translate.instant(title),
      message: this.translate.instant(subTitle),
      buttons: [{
        text: 'OK',
        handler: () => {
          vm.alertPresented = false;

        }
      }],
    });
    // console.log(this.alert)

    this.alert.present();

  }
  presentHandler(title, subTitle, handler) {
    const vm = this;
    if (!vm.alertPresented) {
      vm.alertPresented = true;
      this.alert = vm.alertCtrl.create({
        header: this.translate.instant(title),
        message: this.translate.instant(subTitle),
        buttons: [{
          text: 'Anuller',
          role: 'Cancel',
          handler: () => { }
        }, {
          text: 'OK',
          handler: () => handler
        }],
      });
      vm.alert.present();
    }
  }
  presentHandlerInput(title, subTitle, handler1) {
    const vm = this;
    vm.alertPresented = true;
    this.alert = vm.alertCtrl.create({
      header: this.translate.instant(title),
      message: this.translate.instant(subTitle),
      backdropDismiss: true,

      inputs: [{ type: 'text', name: 'otp' }],
      buttons: [{
        text: 'Anuller',
        role: 'Cancel',
        handler: () => { }
      }, {
        text: 'OK',
        handler: handler1
      }],
    });
    return vm.alert.present();
  }
 async present2Handler(title, subTitle, btn1Txt, btn2Txt, handler1, handler2, ...args) {
    const vm = this;
    // if (!vm.alertPresented) {
    vm.alertPresented = true;
    this.translate.currentLang = 'fr';
    // console.log(this.translate, this.translate.instant(subTitle, args), subTitle, args[0][0])
    this.alert = await vm.alertCtrl.create({
      header: this.translate.instant(title, args[0][0]),
      backdropDismiss: false,
      message: this.translate.instant(subTitle, args[0][0]),
      buttons: [{
        text: btn1Txt,
        role: 'Cancel',
        handler: handler1
      }, {
        text: btn2Txt,
        handler: handler2
      }],
    });
    vm.alert.present();
  }
}

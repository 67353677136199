import { SharedDataService } from 'src/app/providers/shared-data.service';
import { Injectable } from '@angular/core';
import { Http } from '../wrappers/http.service';
import { AlertController } from '@ionic/angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { APP_INFO } from '../configs';

// import * as $ from 'jquery';
import * as Stomp from 'stompjs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';
@Injectable()
export class LoginDataService {
  stompclient: any;
  courseChange$: Subject<any> = new Subject();

  data: any;
  data1: any;
  status: boolean;
  courseList: Array<any>;
  checkIdletime = false;
    idleInterval: any;
    userIdleTime: any;
    userData: any[];
  expireTime = 5;
  subject: any;
  idleState = 'Not started.';
timedOut = false;
min: any;
sec; any;
started: boolean = false;
  userIdle: boolean = false;
  stompClient: any;
  message: any;
  load: boolean;
  cagnoteGames: any[];
  constructor(private sharedData: SharedDataService, private configService: ConfigService,
              private http: Http, public alertCtrl: AlertController, public idle: Idle) {
      // this.data = this.getHippiqueData(date);
  }

  Login(username: string, password: string, isMobile?): Promise<any> {
    let params:any = { channel: 3, channel_id: 3 };
    // console.log(isMobile)
    const url =  isMobile ? this.configService.ServiceUrls.login_with_phonenumber : '';


    if(this.configService.useGateway){
        params.login= username;
        params.pwd = password;
        return new Promise((resolve, reject) => {
          this.http.HPost( this.configService.GATEWAYS.userService + (isMobile ? url :
             this.configService.ServiceUrls.gateway_login) , params).then(data => {
            resolve(data);
              }, error => {
                  // console.log(error);
                  resolve(error);
              });
      });
    }else{
      params.str_Login= username;
      params.str_Password = password;
    
      return new Promise((resolve, reject) => {
          this.http.get_request( this.configService.GATEWAYS.userService +( isMobile ? url :
            this.configService.ServiceUrls.flexbet_login) , params).then(data => {
            // this.connect_session();
                  resolve(data);
              }, error => {
                  // console.log(error);
                  resolve(error);
              });
      });
  }
}
  checkappUpdate(): Promise<any> {
    let data = {};

    data['db_version_number'] = APP_INFO.appVersion;
    data['str_app_type'] = APP_INFO.package;
    return this.http.get(this.configService.ServiceUrls.app_update, data);

}
  refreshSession(): Promise<any> {
    const sid = this.sharedData.getCookie('session');
  //   console.log('sid', sid)
    if (!sid) {
        return new Promise((resolver, reject) => {
            reject('');
        });
    }

    return new Promise((resolve, reject) => {

        this.http.post(this.configService.GATEWAYS.Production_serverAdress + this.configService.ServiceUrls.refreshUser, { sid })
            .subscribe(data => {
                resolve(data);
            }, error => {
                // console.log(error)
                reject(error);
            });
    });
}
  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
        this.http.HPost(this.configService.GATEWAYS.userService + this.configService.ServiceUrls.logout, { sessionToken: this.sharedData.userData.session })
            .then(data => {
              this.sharedData._loggedIn = false;
       
              this.sharedData.userData = undefined;

              this.sharedData.setCookie('session', '', this.configService.expiry);
              this.disconnect();
              // this.sharedData.saveCache('userdata', null);
              const length = this.sharedData._pages.length
              this.sharedData.showLoader();
              this.sharedData._pages[0].icon = 'log-in';
              this.sharedData._pages[0].title = 'ml_login';
              this.sharedData._pages[length - 1].icon = '';
              this.sharedData._pages[length - 1].title = '';
              resolve(data);
            }, error => {
                console.log(error);
                resolve(error);
            });
    });
}
  authSession(id){
    const session = id.session;
    // console.log(session);
    return Promise.resolve(
      this.http
        .HGet(this.configService.GATEWAYS.userService + this.configService.ServiceUrls.loginCustomer + '/' + session)
    );
  }
  async renewSession(data) {
    const params =  {
      agent: data.customer_ID,
      channel: 3
    }
    // console.log(params, this.sharedData.agent_id, this.configService.useGateway);

    return Promise.resolve(
      this.http
        .HPost(this.configService.GATEWAYS.userService + '/user/renew-session', params)

    );
  }

   private connectRabbitSession(me) {
    this.checkIdletime = false;
    this.stompclient.debug = null;
    if (!this.stompclient.connected) {
    if (this.sharedData.userData && this.sharedData.userData.customer_ID){
      this.stompclient.connect(this.configService.RABBIT_CONFIG.USER, this.configService.RABBIT_CONFIG.PASSWD, (frame) => {
        this.subscribeSession();
      }, (message) => {

        setTimeout(() => { me.connect_session(); }, 5000);
      }, '/');
    }
  else{
    setTimeout(() => { me.connect_session(); }, 5000);

  }
    }
  }
  public subscribeSession(){
    if ( this.stompclient.connected){
      this.stompclient.subscribe(`/exchange/${this.configService.RABBIT_CONFIG.EXCHANGE}/${this.configService.RABBIT_CONFIG.QUEUE}.${this.sharedData.userData.customer_ID}`, (m) => {
        const reversedText = m.body.split('').join('');
        const message = JSON.parse(reversedText);
        if (message.hasOwnProperty('data') && message.data.hasOwnProperty('isExpired') && message.data.isExpired) {
          if (this.sharedData._userData.session == message.data.session){
            if (message.isForced){
              // console.log('force logout')
              this.logout();
                } else {
              if (!this.userIdle) {
                const context = this;
                this.renewSession(this.sharedData.userData).then((response: any) => {
                  if (response.erc == '0') {
                    context.sharedData.setCookie('session', response.data.session, this.configService.expiry);
                    context.sharedData.saveCache('userdata', JSON.stringify(response.data));
                    context.sharedData._userData = response.data;
                    // console.log(context.sharedData._userData)
                    context.sharedData._loggedIn = true;
                  }
                  else{
                    this.sharedData.userData = undefined;
                  }
                });

               }
               else{
                this.sharedData.userData = undefined;
                this.sharedData._loggedIn = false;

               }

            }
          }
          else{
            this.logout();
          }
          }
        if (message.hasOwnProperty('data') && message.type == 'balance'){
            this.sharedData.userData.balances = message.data.balances;
          }

      });
    }
  }
  public connect_session() {
    
    const ws = new WebSocket(this.configService.GATEWAYS.Rabbit_Address_user_session);
    this.stompclient = Stomp.over(ws);
    this.connectRabbitSession(this);
  }
  public connect(){
    const ws = new WebSocket(this.configService.GATEWAYS.Rabbit_Address);
    this.stompClient = Stomp.over(ws);
    this.connectRabbit(this);
  }
  public disconnect_rabbit(){
    this.stompClient.disconnect(() => {});
    return this.subject;
  }
  private connectRabbit(me) {
    // console.log('connecting')
    this.stompClient.debug = null;
    this.stompClient.connect('root', 'admin', (frame) => {

      this.stompClient.subscribe('/exchange/flexbet_dashboard/*.*.two_race', (m) => {
        const reversedText = m.body.split('').join('');
        me.processMessage(reversedText);
      });
    }, (message) => {
      // console.log('message', message)
      setTimeout(() => { me.connect(); }, 5000);

    }, '/');

  }
  async processMessage(rawMsg) {
    const message = JSON.parse(rawMsg);
    // console.log(message)
    if (message.hasOwnProperty('message')) {
      let games = {
        'COUPLE GAGNANT': 'CGC3',
        'COUPLE PLACE CM3': 'CpC3',
        'TIERCE CM3': 'TRC3',
        'QUARTE CM3': 'QRC3',
        'QUINTE CM3': 'QNC3',
        'MULTI CM3': 'MULTIC3',
        'QUARTE PLUS CM3': 'QRPC3',
        'QUINTE PLUS CM3': 'QnPC3',
       '2 SUR 4 CM3': '24C3',
        'TIERCE TE2 CM3': 'TRTE2C3',
        TRIO: 'TRIO',
        'PICK 5': 'P5',
        'JUMELE GAGNANT': 'JG',
        'JUMELE ORDRE': 'JO',
        'JUMELE PLACE': 'JP',
        'SIMPLE GAGNANT': 'SG',
        'SIMPLE PLACE': 'SP',
      };
      this.load = true;
      this.sharedData.dismiss();
      this.cagnoteGames = [];

      for (const item of message.message) {
        const cagnote = { reunioun: '', game: '', amount: '' };
        cagnote.reunioun = item.toString().split('-')[0];
        cagnote.amount = item.toString().split(':')[2];
        // let louange1 = item[0].substring(item[0].indexOf('[') + 1, item[0].indexOf(']' ));
        // console.log(louange1)
        const start = item[0].substring(item[0].indexOf('[') + 1, item[0].indexOf(']'));
        // console.log('start' , start);
        cagnote.game = games[start.trim().toUpperCase()].trim();

        this.cagnoteGames.push(cagnote);
      }
      // this.getCagnote.next(this.cagnoteGames)
    }
    if (message.hasOwnProperty('tcourse')) {
      this.courseChange$.next(message.tcourse);
      this.message = message.tcourse;
      // message = message.tcourse;


    }
  }
  public disconnect() {

      this.stompclient.disconnect(() => {

      });

      return this.subject;
    }
    startIdleWatch(data?){
      if (!this.started){
        this.started = true;
        this.idle.setIdle(5);  //after 5 sec idle
        this.idle.setTimeout(1 * 60);  //5min countdown
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleEnd.subscribe(() => {
          this.idleState = 'No longer idle.';
          this.userIdle = false;
          this.idleInterval = 0;
          // console.log(this.userIdle)
    
        }
        );
        this.idle.onIdleStart.subscribe(() => {
          this.idleState = 'You\'ve gone idle!';
          this.idleInterval = this.idleInterval + 1;
          if (this.idleInterval >= 5){
            this.userIdle = true;
            this.idleInterval = 0;
          }
          // console.log(this.userIdle)
        });
    
        this.reload();
    }
    else{
      return;
    }
      }
      reload() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
      }  
      loadSlides(params){
        const perpage = 20;
        // this.searchdata = searchdata;
        // let param = {page:0, perpage: 20, currentPage: 0 };
        
        return this.http.get_request(this.configService.GATEWAYS.sportcash + '/v1/sport/sliders', params);
      }

      resentActivation(params){
        return this.http.Post(this.configService.GATEWAYS.userService + this.configService.ServiceUrls.resendActivation, params);
      }
      async presentAlert(message) {
        const alert = await this.alertCtrl.create({
          cssClass: 'my-custom-class',
          header: '',
          subHeader: '',
          message: this.sharedData.formatAmount(message) + ' ' + this.configService.currency,
          buttons: ['OK']
        });
    
        await alert.present();
      }
}

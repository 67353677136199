import { Injectable } from '@angular/core';
import { SharedDataService } from './shared-data.service';
import { Http } from '../wrappers/http.service';
import {  APP_INFO } from '../configs';
import { Alerts } from '../wrappers/alerts.service';
import { ConfigService } from './config.service';

@Injectable()
export class DataService {
  data: any;
  data1: any;
  status: boolean;
  courseList: Array<any>;
  raceList: any = [];
  dismissloader: boolean;
  sliderItems:[];
  slideArray: any = [];
  public cashInProviders: any = {};

  constructor(private sharedData: SharedDataService, private http: Http, public alertCtrl: Alerts, private configService: ConfigService) {
      //this.data = this.getHippiqueData(date);
  }


  getHippiqueData(date): Promise<any> {
      let params: any = {
          channel : 7,
          versioncode: 3,
          category:  "MISS",
      gameNum:1

      };

      return this.http.get(this.configService.ServiceUrls.getHippiqueData, params);
  }

  saveBet(params): Promise<any> {
      var format = this.sharedData.CurrentgameData.icon + '-' + this.sharedData.CurrentgameData.touteOdre + '-' + 0 + '-' + this.sharedData.CurrentgameData.coefMax + '-' + this.sharedData.CurrentgameData.flexi + '-' + this.sharedData.isSpotGame + '-' + this.sharedData.horseSelectedArray
      //+ '?&channel=' + 7 + '&userid=' + params.userid + '&pwd=' + params.pwd + '&ref=' + params.ref + '&str_From=' + params.str_From + '&format=' + params.format + '&number=' + params.number
      params.channel = 7;
      return this.http.get(this.configService.ServiceUrls.saveBet, params);

  }
  getTransactions(object): Promise<any> {
      // try {
      var params = object;
      params.lg_Customer_Account_ID = this.sharedData.userData.customer_ID;
      params.transactiontype = 'typeTr'
      return this.http.get(this.configService.ServiceUrls.getTransactions, params)
  }
  checkappUpdate(): Promise<any> {
      let data: any = {};

      data.db_version_number = APP_INFO.appVersion;
      data.str_app_type = APP_INFO.package;
      return this.http.get(this.configService.ServiceUrls.app_update, data);

  }
  logout(): Promise<any> {
      return new Promise((resolve, reject) => {
          this.http.post(this.configService.GATEWAYS.Production_serverAdress + this.configService.ServiceUrls.logout, { 'sessionToken': this.sharedData.userData.session })
              .subscribe(data => {
                  resolve(data);
              }, error => {
                //   console.log(error);
                  resolve(error);
              });
      })
  }

  refreshSession(): Promise<any> {
      const sid = this.sharedData.getCookie('session');
    //   console.log('sid',sid)
      if (!sid)
          return;
      return new Promise((resolve, reject) => {

          this.http.post(this.configService.GATEWAYS.Production_serverAdress + this.configService.ServiceUrls.refreshUser, { 'sid': sid })
               .subscribe(data => {
                  resolve(data);
              }, error => {
                //   console.log(error);
                  resolve(error);
              });
      })
  }

  verifyAccnt(data): Promise<any> {
      return new Promise((resolve, reject) => {
          this.http.post(this.configService.GATEWAYS.Production_serverAdress + this.configService.ServiceUrls.verifyAccnt, data)
              .subscribe(data => {
                  resolve(data);
              }, error => {
                //   console.log(error);
                  resolve(error);
              });
      })

  }
  confirmAccnt(data): Promise<any> {
      return new Promise((resolve, reject) => {
          this.http.post(this.configService.GATEWAYS.Production_serverAdress + this.configService.ServiceUrls.addAccnt, data)
              .subscribe(data => {
                  resolve(data);
              }, error => {
                //   console.log(error);
                  resolve(error);
              });
      })

  }

  sortByKey(array) {
    return array.sort(function(a,b) {
      var keyA = a['racedt'],
        keyB = b['racedt'];
      // Compare the 2 dates
      return ((keyA < keyB) ? -1 : ((keyA > keyB) ? 1 : 0));
    });
    }

getProviders(id: any): Promise<any> {
        const api = id === 1 ? this.configService.ServiceUrls.cashInProviders : this.configService.ServiceUrls.cashOutProviders;
        if(this.cashInProviders[id])
        return Promise.resolve(this.cashInProviders[id]);
        return this.http.get_request(this.configService.GATEWAYS.userService + this.configService.AccountUrls.providers + api, {}).then(response => {
          const providers = [];
          response.data.forEach(element => {
            if (element.hasOwnProperty('sub_providers') && element.sub_providers.length > 0) {
              element.sub_providers.forEach(subProv => {
                subProv.pdv = element.code;
                providers.push(subProv);
              });
            } else {
              element.pdv = element.code;
              providers.push(element);
            }
          });
          this.cashInProviders[id] = providers;
          if (id == 2){
            let context = this;
          if(context.sharedData.userData && context.sharedData.userData.providers && context.sharedData.userData.providers.length > 0){
            this.cashInProviders[2]  = this.cashInProviders[2].filter(function(array_el){
              return context.sharedData.userData.providers.filter(function(anotherOne_el){
                 return (anotherOne_el.pvd == array_el.code || !array_el.is_mobile);
              }).length >= 1
           });
          }
          else{
            this.cashInProviders[2]  = this.cashInProviders[2].filter(function(array_el){
                 return (!array_el.is_mobile);
           });
          }
          }
        });
      }

}

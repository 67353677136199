import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from './date.pipe';
import { sortDatePipe } from './dateSortPipe';
import { FormatOelPipe } from './format-oel.pipe';
import { FormatAmountPipe } from './format-amount.pipe';



@NgModule({
  declarations: [DatePipe,sortDatePipe, FormatOelPipe, FormatAmountPipe],
  exports: [DatePipe,sortDatePipe, FormatOelPipe,FormatAmountPipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { SharedDataService } from './providers/shared-data.service';
import { Http } from './wrappers/http.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Alerts } from './wrappers/alerts.service';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { LoginDataService } from './providers/login-data.service';
import { PipesModule } from './pipes/pipes.module';
import { DataService } from './providers/data-service.service';
import { PromotionService } from './providers/promotion.service';
import { RapportService } from './providers/rapport.service';
import { Services } from './providers/main.service';
import { AccountService } from './providers/account.service';
import { RaceService } from './providers/race.service';
// import { RecaptchaModule } from 'ng-recaptcha'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ConfigService } from './providers/config.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

export function configFactory(config: ConfigService, shared: SharedDataService) {

  return () => config.loadConfigs().then(data => {
    
  });
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule],
  providers: [
    StatusBar,
    SplashScreen,
    Alerts,
    SharedDataService,
    TranslateService,
    DataService,
    PromotionService,
    RapportService,
    FormBuilder,
    LoginDataService,
    PipesModule,
    Services,
    AccountService,
    Http,
    ConfigService,
    NavParams,
    RaceService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    ConfigService, {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    }],
  exports: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

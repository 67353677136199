import { Injectable } from '@angular/core';
import { Http } from '../wrappers/http.service';
import { Alerts } from '../wrappers/alerts.service';
import { ConfigService } from './config.service';

@Injectable()
export class AccountService {
  data: any;
  data1: any;
  status: boolean;

  courseList: Array<any>;
  constructor(private http: Http, private configService: ConfigService, public alertCtrl: Alerts) {

  }
  getAccountBalance(client) {
    //   console.log(client);
      return new Promise((resolve, reject) => {
        // let  url=this.configService.AccountUrls.getSolde + 'lg_Client_ID=' + client;
          this.http.get(this.configService.AccountUrls.getSolde , {lg_Client_ID: client}).then(data => {
              // console.log('what i return', url, data)
                  resolve(data)
              }, error => {
                  // console.log(error)
                  resolve(error)
              });
      });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datecustom'
})
export class DatePipe implements PipeTransform {
  constructor() {

  }
  transform(value, args?: string[]): any {
    let returnValue = "";
    if (value !== undefined) {
      value = value[0] + " " + value[1]
      var event = new Date(value);
      if (Object.prototype.toString.call(event) === "[object Date]") {
        if (isNaN(event.getTime())) { 
          return event;
        } else {
           var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false };
          returnValue = event.toLocaleDateString('fr', options);

          return returnValue.replace(":","h");
        }
      } else {
        // not a date
      }
      var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false };
      returnValue = event.toLocaleDateString('fr', options);
      return returnValue;
    }
  }
}

export class Config {}
export let SERVER_NAME = "myServer.com";
export let TEST_USERNAME = "myName";
// export let externalvaraibles = {
//   currency: 'XOF'
// };
export let REFRESHTIME = 60000;
export let BASE_CLASS = {
  activerace: "itemHeader",
  endedRace: "racefinishedHeader",
};
export let APP_INFO = {
  serViceversion: 3,
  appVersion: 1.2,
  package: "com.flexbet.hippique",
  appName: "Hippique",
};

export let Endpoints = {
  races: "/ser_hippique_json",
  getHippiqueData: "/soccer/get_active_games.jsp",
  flexbet_login: "/agent/ws_login_customer.jsp",
  gateway_login: "/user/auth",
  login_with_phonenumber: "/user/auth-phone-number",
  loginCustomer: "/user/auth",
  saveBet: "/ser_hippique_bet",
  register_customer: "/customer-service/new",
  flexbet_register_customer: "/agent/ws_create_customer.jsp?",
  getTransactions: "/ser_customer_transaction",
  getRaceResults: "/ser_hippique_json",
  raceRapport: "/ws_web_mobile_rapport.jsp",
  app_update: "/check_app_update.jsp",
  logout: "/user/close-session",
  refreshUser: "/API/customer/session/info",
  lonaci_refreshUser: "/API/customer/session/info",
  ws_reset_custumer_account: "/agent/resetpassword_stage1.jsp",
  ws_reset_custumer_account2: "/agent/resetpassword_stage2.jsp",
  change_password: "/agent/resetpaasword_stage3.jsp",
  slider: "/system/sliders",
  pub: "/system/publication/list",
  verifyAccnt: "/API/customer/provider/sendConfirmCode",
  addAccnt: "/API/customer/provider/confirmAccount",
  getConfigs: "/v1/configs",
  gratuits: "/gameList/getGratuitPdf",
  promotions: "/site_info/all",
  registerAnonym: "/customer-service/register_ussd",
  placeBetMiss: "web/miss",
  cashInProviders: "/cashInProviders",
  cashOutProviders: "/cashOutProviders"
};

export let AccountUrls = {
  pmucDeposit: "/coupon_refill_local.jsp",
  resendActivation: "/user/resendActivation",
  getHippiqueData: "/soccer/get_active_games.jsp",
  editUserInfo: "/user/updateProfile",
  loginCustomer: "/user/auth",
  login_with_phonenumber: "/user/auth-phone-number",
  slider: "/system/sliders",
  pub: "/system/publication/list",
  saveBet: "/ser_hippique_bet",
  register_customer: "/customer-service/new",
  flexbet_register_customer: "/customer-service/new",
  getTransactions: "/ser_customer_transaction",
  getRaceResults: "/ser_hippique_json",
  raceRapport: "/ws_web_mobile_rapport.jsp",
  app_update: "/check_app_update.jsp",
  ws_reset_custumer_account: "/agent/resetpassword_stage1.jsp",
  ws_reset_custumer_account2: "/agent/resetpassword_stage2.jsp",
  change_password: "/agent/resetpaasword_stage3.jsp",
  logoutCustomer: "/user/close-session",
  logout: "/user/close-session",
  renewSession: "/user/session/renew",
  refreshUser: "/API/customer/session/info",
  verifyAccnt: "/API/customer/provider/sendConfirmCode",
  addAccnt: "/API/customer/provider/confirmAccount",
  sessionverification: "/API/customer/session/validate",
  flexbet_transactions: "/agent/getcustomerTransaction.jsp",
  airtelDeposit: "/airtel_mobile.jsp",
  airtelWithdrawal: "/airtel_mobile.jsp",
  getSolde: "/get_Solde.jsp",
  pmucWithdrawal: "/get_RefCode.jsp",
  sendOtp: "/API/customer/otp/send",
  providers: "/provider-service",
};

export let _AccountUrls = {
  DizaPAY: "/API/ser_web_recharge_momo_box?",
  W_DizaPAY: "/API/ser_web_retrait_momo_box?",
  pmucDeposit: "/coupon_refill_local.jsp?",
  getSolde: "/get_Solde.jsp",
  orangeDeposit_v2: "/ser_orange_money_payment?",
  orangeDeposit: "/recharge_Orange_Money.jsp?",
  mtnDeposit: "/mobile_money.jsp?",
  pmucWithdraw: "/get_RefCode.jsp?",
  verifyPhone: "/ussd_service/ws_verify_phone.jsp?",
  _editUserInfo: "/agent/ws_update_customer.jsp?",
  _getSolde: "/get_Solde.jsp",
  editUserInfo: "/user/updateProfile",
  airtelMobile: "/airtel_mobile.jsp?",
};

export const GameTypes = {
  CE: "C. Elargie",
  SI: "Simple",
  CP: "Champ Partiel",
  CT: "Champ Total",
};

export const _GAME_TYPES = {
  CE: "Long Champ",
  SI: "Unitaire",
  CP: "Champ Reduit",
  CT: "Champ Total",
};

// export const dateJeu = "2022-06-21"
export const userDefaults = {pin: '1049', code: '225'}

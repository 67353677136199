import { Injectable } from '@angular/core';
import { Http } from '../wrappers/http.service';
import { ConfigService } from './config.service';

@Injectable()
export class PromotionService {

  data1 = [{"title":"Meeting De Deauville"},{"icon":"e.couple.png"},{"header":"header.png"},
                     {"date":"Du Samedi 13 août au dimanche 14 août"},{"name":"e.quinte.png"},{"name":"e.simple.png"},
                     {"name":"e.tierce.png"},{"name":"e.trio.png"}];

    constructor(private http: Http, private configService: ConfigService){
        
    }

data: any;
loadPromotions(params:any = {}){

  // params.time = new Date().getTime();

  return this.http.get_request(
    this.configService.GATEWAYS.userService + this.configService.ServiceUrls.promotions,
    params
  );
}
loadPublications(params:any = {}){
  params.status = true;
  params.time = new Date().getTime()
  return this.http.get_request(
    this.configService.GATEWAYS.userService + this.configService.ServiceUrls.pub,
    params
  );
}

public downloadGuide(url, name?) {
  let pdfReqBody = {
    // url: url,
    // title: name,
    // any usefull data you would want to send to service
  };

  this.http
    .get_request(url, pdfReqBody, { responseType: "blob" })
    .then((res) => {
      let blob = new Blob([res], { type: "application/pdf" });
      let pdfUrl = window.URL.createObjectURL(blob);

      var PDF_link = document.createElement("a");
      PDF_link.href = pdfUrl;
      //   TO OPEN PDF ON BROWSER IN NEW TAB
      // window.open(pdfUrl, '_blank');
      //   TO DOWNLOAD PDF TO YOUR COMPUTER
      PDF_link.download = `${name.replaceAll(" ", "-")}.pdf`;
      PDF_link.click();
    });
}
}

import { Injectable } from '@angular/core';
import { Alerts } from './alerts.service';
import { TranslateService} from '@ngx-translate/core'
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { ConfigService } from '../providers/config.service';


@Injectable()
export class Http {

  constructor(public http: HttpClient,private configService: ConfigService, public alertCtrl: Alerts, public translate: TranslateService) {

  }

  get(api: any, params: any): Promise<any> {
      try {
          let url = this.configService.GATEWAYS.Production_serverAdress + api + (Object.keys(params).length > 0 ? this.parseParams(params) : '');

          return new Promise((resolve, reject) => {
              this.http.get(url)
              .subscribe(data => {
                resolve(data);
            }, error => {
                reject(error);
            });

          });
      } catch (err) {
          return new Promise(reject => {
              reject(err);

          });
      }


  }
  get_request(api: any, params: any, opts?: any): Promise<any> {
    try {
        const url = api + (Object.keys(params).length > 0 ? this.parseParams(params) : '');

        return new Promise((resolve, reject) => {
            this.http.get(url, opts)
            .subscribe(data => {
              resolve(data);
          }, error => {
              reject(error);
          });

        });
    } catch (err) {
        return new Promise(reject => {
            reject(err);

        });
    }


}
  post(url: any, params: any) {

      return this.http.post(url, params);
  }
  Post(url: any, params: any, header?: any): Promise<any> {
    params.channel = 7;
    let requestHeaders = header ? header : null;

    const me = this;
    return new Promise((resolve, reject) => {
      this.http.post(url, params, { 'headers': requestHeaders })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }
  generateHash(body) {
    const secretKey = this.configService.AuthParams.password
    secretKey.replace(/'/g, '');
    const hash = CryptoJS.HmacSHA256(body, secretKey);
    const hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    // console.log(hashInBase64);
    return hashInBase64;
  }
  HPost(url: any, data: any) {
    const body = data;
    const headers = { 'Content-Type' : '', 'Content-Signature': ''};
    headers['Content-Type'] = 'application/json';

    // Object.keys(data).forEach((key) => {
    //   body = body.append(key, encodeURI(data[key]));
    // });
    headers['Content-Signature'] =  this.configService.AuthParams.username +':' + this.generateHash(JSON.stringify(body));
// + ';charset=UTF-8' x-www-form-urlencoded
    return new Promise((resolve, reject) => {
      this.http.post(url, body, { headers }).subscribe(data => {
        document.body.style.cursor = 'default';
        resolve(data);
      }, error => {
        document.body.style.cursor = 'default';
        console.log('data', JSON.stringify(error));
        resolve(error);
      });
    });

  }
  HGet(url: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    headers = headers.set('Content-Signature', this.configService.AuthParams.username +':' + this.generateHash(this.configService.AuthParams.username) );
// + ';charset=UTF-8'
    return new Promise((resolve, reject) => {
      this.http.get(url,  { headers }).subscribe(data => {
        document.body.style.cursor = 'default';
        resolve(data);
      }, error => {
        document.body.style.cursor = 'default';
        console.log('data', JSON.stringify(error));
        reject(error);
      });
    });

  }

  HttpParamsPost(url: any, data: any) {
    let body = new HttpParams();
  Object.keys(data).forEach(function (key) {
       body = body.append(key,encodeURI(data[key]));
  });
    // console.log('Request body',body )
    return new Promise((resolve, reject) => { this.http.post(url,
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    ).subscribe(data => {
      document.body.style.cursor = 'default';
      resolve(data);
    }, error => {
      document.body.style.cursor = 'default';
      // console.debugerror("data",JSON.stringify(error));
      resolve(error);
    });
  });;
  
  }
  parseParams(data: any): any {
      var arr = Object.keys(data)
      var queryParams = '?';
      for (var i = 0; i < arr.length; i++) {
          queryParams = queryParams + arr[i] + '=' + data[arr[i]] + '&';
      }
      return queryParams;
  }}

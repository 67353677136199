import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthParams, RABBIT_CONFIG } from 'src/environments/environment';
import { AccountUrls, Endpoints, GameTypes, userDefaults } from '../configs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  ServiceUrls: any = {};
  GATEWAYS: any = {};
  RABBIT_CONFIG: any = {};
  GAME_TYPES: any;
  AccountUrls: any;
  AuthParams:any = {};
  expiry: any;
  channel: any;
  gameDate: string;
  APP_ID: string;
  USER_DEFAULTS: any;
  private _currency = 'X0F';
  private _useGateway = false;
  private _quick_signup_enabled = 0;
  private _phoneNumberLength = 10;
  private _login_required;
  private _usePromoCode = 0;
  private _tax: any;
  public currencyStep = 10;
  countryCode: any;
  countries: Array<any> = [];
  _dialingCodes: any;
  RECEIVER_PHONENUMBER: any;
  private _age_verification_required: any = 0;
  private _min_age: number;
  STR_MISS_CI_PIN: any;
  public get age_verification_required() {
    return this._age_verification_required;
  }
  public set age_verification_required(value) {
    this._age_verification_required = value;
  }
  public get min_age(){
    return this._min_age;
  }
  public set min_age(val: number){
    this._min_age = val
  }
  public get currency() {
    return this._currency;
  }
  public set currency(value) {
    this._currency = value;
  }
  public get tax() {
    return this._tax;
  }
  public set tax(value) {
    this._tax = value;
  }
  public get useGateway() {
    return this._useGateway;
  }
  public set useGateway(value) {
    this._useGateway = value;
  }
  public get phoneNumberLength(){
    return this._phoneNumberLength;
  }
  public set phoneNumberLength(value){
    this._phoneNumberLength = value;
  }

  get dialingCodes() {
    return this._dialingCodes;
}
set dialingCodes(codes: Array<any>) {
    this._dialingCodes = codes;
}
  public get quick_signup_enabled() {
    return this._quick_signup_enabled;
  }
  public set quick_signup_enabled(value) {
    this._quick_signup_enabled = value;
  }
  public get login_required(){
    return this._login_required;
  }
  public set login_required (value){
    this._login_required = value;
  }
  public get usePromoCode() {
    return this._usePromoCode
  }
  public set usePromoCode(value) {
    this._usePromoCode = value;
  }
  constructor(private http: HttpClient) {
    // console.log('Before call from config service ');


  }
  $config: Subject<any> = new Subject();

  loadConfigs() {
    try {
      this.loadCountries()
    } catch (err) {
      console.log(err)
    } 
    return new Promise((resolve, reject) => {
      this.http.get('assets/config/config.json').subscribe(
        (res: any) => {
          this.GATEWAYS = res.gateways;
          this.ServiceUrls = Endpoints;
          this.GAME_TYPES = GameTypes;
          this.RECEIVER_PHONENUMBER = res.RECEIVER_PHONENUMBER;
          this.AccountUrls = AccountUrls;
          this.expiry = res.expiry;
          this.AuthParams = AuthParams;
          this.channel = res.channel;
          this.currencyStep = res.currencyStep
          this.RABBIT_CONFIG = RABBIT_CONFIG;
          this.gameDate = res.dateJeu;
          this.APP_ID = res.ussdid
          this.USER_DEFAULTS = userDefaults
          this.$config.next(res);
          resolve(res)
        }
      )
    } )

  }
  loadCountries() {
    let context=this;
    return new Promise((resolve, reject) => {
      this.http.get('assets/countries.json').subscribe(
        (res: any) => {
          context.countries = res;
          resolve(res)
        }
      )
    })
  }
}

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'sortDate',
    pure: false
})
export class sortDatePipe implements PipeTransform {
    constructor() {

    }
    transform(arry: any,field:any) {
      var data = arry.sort((data1,data2)=>{
           return new Date(data1[field]).getTime()-new Date(data2[field]).getTime()
       })
       return data
    }
}
export interface Theme {
  name: string;
  useGateway: boolean,
  properties: any;
  country: string;
}

export const lonagui: Theme = {
  name: 'lonagui',
  useGateway: false,
  properties: {
    "--ion-color-primary": "#008f4c",

    //  homepage
    "--ion-color-activeheader": "#fff",
    "--ion-background-activeheader": "#027835",
    "--ion-color-activeReunion": "#5d5d5d",
    "--ion-color-activeRace": "#fcd016",
    "--ion-color-race-card-details": "#7d7c7c",
    "--ion-color-meetings-header": "#027835",
    "--ion-background-race-card": "#fff",
    "--ion-background-home": "#eaeaea",
    "--ion-border-race-card": "#fff",
    "--ion-color-race-for-theday": "#626262",
    "--ion-color-finishheader": "#fff",
    "--ion-background-finishheader": "#aaaaaa",
    "--ion-color-monCompteBtn": "#008f4c",
    "--ion-color-monCompte-label": "#eb882f",

    // rapport page
    "--ion-background-middlecomponent": "#fff",
    "--ion-background-arriveecomponent": "#fff",
    "--ion-color-rapport-footer": "#fff",
    "--ion-background-rapport-footer": "#000",

    // header 
    "--ion-color-header": "#fff",
    "--ion-background-header": "#fff",

    "--ion-color-account-icon": "#fff",
    "--ion-color-account-info": "#fff",
    "--ion-color-account-header": "#027835",

    // loging page
    "--ion-background-image-url-login": "url('assets/img/lonagui_bgLogin.jpg')",
    "--ion-background-image-url-login-position": "unset",
    "--ion-color-activate-acc": "#999a9c",
    "--ion-color-forgot-pwd": "#999a9c",
    "--ion-color-login-icon-floating": "#4A4A4A",
    "--ion-color-login-label": "#4A4A4A",
    "--ion-background-login-input": "#fff",
    "--ion-color-fg-pw-text": "#2dd36f",
    // transaction page
    "--ion-color-icon-calendar": "#009d48",
    "--ion-color-calendar": "#5d5d5d",
    "--ion-background-calendar-btn": "#009d48",
    // cheval page
    "--ion-color-cheval-race": "#cb1426",
    "--ion-color-pink-cheval-race": "#cb1426",
    "--ion-color-cheval-RC": "#027835",
    "--ion-color-cheval-time": "#027835",
    "--ion-color-cheval-horse_banner": "#fff",
    "--ion-background-cheval-horse_banner": "#027835",
    "--ion-color-cheval-horse_number": "#cb14269c",
    "--ion-color-cheval-horse-details": "#027835",
    "--ion-color-cheval-horse-details-labels": "#027835",
    "--ion-color-cheval-horse-details-values": "#505050",
    // reunion page
    "--ion-color-reunion-date": "#027835",
    "--ion-color-reunion-name": "#505050",
    "--ion-background-reunion-name": "#fcd016",
    // Races page
    "--icon-color-race_reun_num": "#eb862c",
    "--icon-background-depart": "#888484de",
    // account page
    "--ion-background-acc-card": "#fcd016",
    "--ion-color-acc-card": "#fff",
    "--ion-color-acc-card-icon": "#fff",
    // registration page
    "--ion-color-reg-icon-up": "#027835",
    "--icon-color-reg-icon-next": "#ffc409f5",
    "--icon-background-reg-next": "#ffc409f5",
    "--icon-background-reg-header": "#ffc409f5",
    "--icon-color-reg-header": "#fff",
    "--icon-color-reg-main-header": "#027835",
    "--ion-color-text-shadow": "#fff",
    // account
    "--ion-color-confirm-transaction": "#fff",
    "--ion-background-confirm-transaction": "#bbbeca",


    "--ion-color-heading-tint": "#6B6B6B",


    // ion buttons
    "--ion-button-color-primary": "#fff",
    "--ion-button-background-primary": "#bbbeca",
    "--ion-button-color-secondary": "#fff",
    "--ion-button-background-secondary": "#ffc409f5",
    "--ion-button-color-monCompte": "#fff",
    "--ion-button-color-monCompte-border": "#fff",
    "--ion-button-background-monCompte": "#ffc409f5",
    "--ion-button-navs-active": "#027835",
    "--ion-button-navs-not-active": "#d4d4d4",
    "--ion-button-navs-color-not-active": "#3d3d3d",
    "--ion-button-navs-color-active": "#fff",
    // "--ion-button-color-primary": "",
    // ion checkbox
    "--ion-color-checkbox-border": "#bbbeca",
    "--ion-background-checkbox": "#fff",
    "--ion-color-checkbox-border-checked": "#fcd016",
    "--ion-background-checkbox-checked": "#fcd016",
    "--ion-color-checkbox-checkmark": "#fff",

    "--app-background-gray-tint": "#5d5d5d"
  },
  country: "GI"
}

export const lonaci: Theme = {
  name: 'lonaci',
  useGateway: true,
  properties: {
    "--ion-color-primary": "#008f4c",
    //  homepage
    "--ion-color-activeheader": "#fff",
    "--ion-background-activeheader": "#009d48",
    "--ion-color-activeReunion": "#5d5d5d",
    "--ion-color-activeRace": "#eb862c",
    "--ion-color-race-card-details": "#7d7c7c",
    "--ion-color-meetings-header": "#009d48",
    "--ion-background-race-card": "#fff",
    "--ion-background-home": "#eaeaea",
    "--ion-border-race-card": "#fff",
    "--ion-color-race-for-theday": "#626262",
    "--ion-color-finishheader": "#fff",
    "--ion-background-finishheader": "#aaaaaa",
    "--ion-color-monCompteBtn": "#008f4c",
    "--ion-color-monCompte-label": "#eb882f",
    // rapport page
    "--ion-background-middlecomponent": "#fff",
    "--ion-background-arriveecomponent": "#fff",
    "--ion-color-rapport-footer": "#fff",
    "--ion-background-rapport-footer": "#000",

    // header 
    "--ion-color-header": "#fff",
    "--ion-background-header": "#fff",

    "--ion-color-account-icon": "#fff",
    "--ion-color-account-info": "#fff",
    // loging page
    "--ion-background-image-url-login": "url('assets/img/lonaci_bgLogin.jpg')",
    "--ion-background-image-url-login-position": "50% -100px",
    "--ion-color-activate-acc": "#009d48",
    "--ion-color-fg-pw-text": "#2dd36f",
    "--ion-color-forgot-pwd": "#eb882f",
    "--ion-color-login-icon-floating": "#009d48",
    "--ion-color-login-label": "#4A4A4A",
    "--ion-button-background-registration": "#5d5d5d",
    "--ion-button-color-registration": "#fff",
    "--ion-button-color-registration-border": "#5d5d5d",
    "--ion-button-background-login": "#eb882f",
    "--ion-button-color-login": "#fff",
    "--ion-button-color-login-border": "#eb882f",
    "--ion-color-phone-login": "#5d5d5d",
    // cheval page
    "--ion-color-cheval-race": "#eb882f",
    "--ion-color-pink-cheval-race": "#aa0078",
    "--ion-color-cheval-RC": "#eb882f",
    "--ion-color-cheval-time": "#505050",
    "--ion-color-cheval-horse_banner": "#fff",
    "--ion-background-cheval-horse_banner": "#009d48",
    "--ion-color-cheval-horse_number": "#009d48",
    "--ion-color-cheval-horse-details": "#fff",
    "--ion-color-cheval-horse-details-labels": "#505050",
    "--ion-color-cheval-horse-details-values": "#505050",

    // reunion page
    "--ion-color-reunion-date": "#505050",
    "--ion-color-reunion-name": "#505050",
    "--ion-background-reunion-name": "#eb882f",

    // account page
    "--ion-background-acc-card": "#eb882f",
    "--ion-color-acc-card": "#fff",
    "--ion-color-acc-card-icon": "#fff",
    // registration page
    "--ion-color-reg-icon-up": "#fff",
    "--icon-color-reg-icon-next": "#eb882f",
    "--icon-background-reg-next": "#eb882f",
    "--icon-background-reg-header": "#eb882f",
    "--icon-color-reg-header": "#fff",
    "--icon-color-reg-main-header": "#505050",


    // account
    "--ion-color-confirm-transaction": "#fff",
    "--ion-background-confirm-transaction": "#505050",
    // transaction page
    "--ion-color-icon-calendar": "#009d48",
    "--ion-color-calendar": "#5d5d5d",
    "--ion-background-calendar-btn": "#009d48",
    // Races page
    "--icon-color-race_reun_num": "#eb862c",
    "--icon-background-depart": "#888484de",

    "--ion-color-heading-tint": "#6B6B6B",

    "--ion-color-text-shadow": "#fff",
    // ion buttons
    "--ion-button-color-primary": "#fff",
    "--ion-button-background-primary": "#505050",
    "--ion-button-color-secondary": "#fff",
    "--ion-button-background-secondary": "#eb862c",
    "--ion-button-color-monCompte": "#fff",
    "--ion-button-color-monCompte-border": "#fff",
    "--ion-button-background-monCompte": "#eb862c",
    "--ion-button-navs-active": "#009d48",
    "--ion-button-navs-not-active": "#009d48",
    "--ion-button-navs-color-not-active": "#3d3d3d",
    "--ion-button-navs-color-active": "#fff",
    // "--ion-button-color-primary": "",
    // ion checkbox
    "--ion-color-checkbox-border": "#505050",
    "--ion-background-checkbox": "#fff",
    "--ion-color-checkbox-border-checked": "#eb862c",
    "--ion-background-checkbox-checked": "#eb862c",
    "--ion-color-checkbox-checkmark": "#fff",

    "--app-background-gray-tint": "#5d5d5d"


  },
  country: "CI"
}
export const missci: Theme = {
  name: 'missci',
  useGateway: true,
  properties: {
    "--ion-color-primary": "#008f4c",
    //  homepage
    "--ion-color-activeheader": "#fff",
    "--ion-background-activeheader": "#009d48",
    "--ion-color-activeReunion": "#5d5d5d",
    "--ion-color-activeRace": "#eb862c",
    "--ion-color-race-card-details": "#7d7c7c",
    "--ion-color-meetings-header": "#009d48",
    "--ion-background-race-card": "#fff",
    "--ion-background-home": "#eaeaea",
    "--ion-border-race-card": "#fff",
    "--ion-color-race-for-theday": "#626262",
    "--ion-color-finishheader": "#fff",
    "--ion-background-finishheader": "#aaaaaa",
    "--ion-color-monCompteBtn": "#008f4c",
    "--ion-color-monCompte-label": "#eb882f",
    // rapport page
    "--ion-background-middlecomponent": "#fff",
    "--ion-background-arriveecomponent": "#fff",
    "--ion-color-rapport-footer": "#fff",
    "--ion-background-rapport-footer": "#000",

    // header 
    "--ion-color-header": "#fff",
    "--ion-background-header": "#fff",

    "--ion-color-account-icon": "#fff",
    "--ion-color-account-info": "#fff",
    // loging page
    "--ion-background-image-url-login": "url('assets/img/lonaci_bgLogin.jpg')",
    "--ion-background-image-url-login-position": "50% -100px",
    "--ion-color-activate-acc": "#009d48",
    "--ion-color-fg-pw-text": "#2dd36f",
    "--ion-color-forgot-pwd": "#eb882f",
    "--ion-color-login-icon-floating": "#009d48",
    "--ion-color-login-label": "#4A4A4A",
    "--ion-button-background-registration": "#5d5d5d",
    "--ion-button-color-registration": "#fff",
    "--ion-button-color-registration-border": "#5d5d5d",
    "--ion-button-background-login": "#eb882f",
    "--ion-button-color-login": "#fff",
    "--ion-button-color-login-border": "#eb882f",
    "--ion-color-phone-login": "#5d5d5d",
    // cheval page
    "--ion-color-cheval-race": "#eb882f",
    "--ion-color-pink-cheval-race": "#aa0078",
    "--ion-color-cheval-RC": "#eb882f",
    "--ion-color-cheval-time": "#505050",
    "--ion-color-cheval-horse_banner": "#fff",
    "--ion-background-cheval-horse_banner": "#009d48",
    "--ion-color-cheval-horse_number": "#009d48",
    "--ion-color-cheval-horse-details": "#fff",
    "--ion-color-cheval-horse-details-labels": "#505050",
    "--ion-color-cheval-horse-details-values": "#505050",

    // reunion page
    "--ion-color-reunion-date": "#505050",
    "--ion-color-reunion-name": "#505050",
    "--ion-background-reunion-name": "#eb882f",

    // account page
    "--ion-background-acc-card": "#eb882f",
    "--ion-color-acc-card": "#fff",
    "--ion-color-acc-card-icon": "#fff",
    // registration page
    "--ion-color-reg-icon-up": "#fff",
    "--icon-color-reg-icon-next": "#eb882f",
    "--icon-background-reg-next": "#eb882f",
    "--icon-background-reg-header": "#eb882f",
    "--icon-color-reg-header": "#fff",
    "--icon-color-reg-main-header": "#505050",


    // account
    "--ion-color-confirm-transaction": "#fff",
    "--ion-background-confirm-transaction": "#505050",
    // transaction page
    "--ion-color-icon-calendar": "#009d48",
    "--ion-color-calendar": "#5d5d5d",
    "--ion-background-calendar-btn": "#009d48",
    // Races page
    "--icon-color-race_reun_num": "#eb862c",
    "--icon-background-depart": "#888484de",

    "--ion-color-heading-tint": "#6B6B6B",

    "--ion-color-text-shadow": "#fff",
    // ion buttons
    "--ion-button-color-primary": "#fff",
    "--ion-button-background-primary": "#505050",
    "--ion-button-color-secondary": "#fff",
    "--ion-button-background-secondary": "#eb862c",
    "--ion-button-color-monCompte": "#fff",
    "--ion-button-color-monCompte-border": "#fff",
    "--ion-button-background-monCompte": "#eb862c",
    "--ion-button-navs-active": "#009d48",
    "--ion-button-navs-not-active": "#009d48",
    "--ion-button-navs-color-not-active": "#3d3d3d",
    "--ion-button-navs-color-active": "#fff",
    // "--ion-button-color-primary": "",
    // ion checkbox
    "--ion-color-checkbox-border": "#505050",
    "--ion-background-checkbox": "#fff",
    "--ion-color-checkbox-border-checked": "#eb862c",
    "--ion-background-checkbox-checked": "#eb862c",
    "--ion-color-checkbox-checkmark": "#fff",

    "--app-background-gray-tint": "#5d5d5d"


  },
  country: "CI"
}
export const pmug: Theme = {
  name: 'pmug',
  useGateway: false,
  properties: {
    "--ion-color-primary": "#e61780",
    //  homepage
    "--ion-color-activeheader": "#fff",
    "--ion-background-activeheader": "#e61780",
    "--ion-color-activeReunion": "#5d5d5d",
    "--ion-color-activeRace": "#7fc41c",
    "--ion-color-race-card-details": "#7d7c7c",
    "--ion-color-meetings-header": "#e61780",
    "--ion-background-race-card": "#fff",
    "--ion-background-home": "#eaeaea",
    "--ion-border-race-card": "#fff",
    "--ion-color-race-for-theday": "#626262",
    "--ion-color-finishheader": "#fff",
    "--ion-background-finishheader": "#aaaaaa",
    "--ion-color-monCompteBtn": "#5d5d5d",
    "--ion-color-monCompte-label": "#e61780",
    // rapport page
    "--ion-background-middlecomponent": "#fff",
    "--ion-background-arriveecomponent": "#fff",
    "--ion-color-rapport-footer": "#fff",
    "--ion-background-rapport-footer": "#000",

    // header 
    "--ion-color-header": "#fff",
    "--ion-background-header": "#fff",

    "--ion-color-account-icon": "#fff",
    "--ion-color-account-info": "#fff",
    // login page
    "--ion-background-image-url-login": "url('assets/img/lonaci_bgLogin.jpg')",
    "--ion-background-image-url-login-position": "50% -100px",
    "--ion-color-activate-acc": "#e61780",
    "--ion-color-forgot-pwd": "#7fc41c",
    "--ion-color-login-icon-floating": "#5d5d5d",
    "--ion-color-fg-pw-text": "#ef238e",
    "--ion-button-background-registration": "#ebebeb",
    "--ion-button-color-registration": "#ef238e",
    "--ion-button-color-registration-border": "#ef238e",
    "--ion-button-background-login": "#ef238e",
    "--ion-button-color-login": "#fff",
    "--ion-button-color-login-border": "#ef238e",
    "--ion-color-phone-login": "#5d5d5d",
    "--ion-color-login-label": "#4A4A4A",

    "--ion-color-heading-tint": "#6B6B6B",

    // cheval page
    "--ion-color-cheval-race": "#7fc41c",
    "--ion-color-pink-cheval-race": "#aa0078",
    "--ion-color-cheval-RC": "#5d5d5d",
    "--ion-color-cheval-time": "#505050",
    "--ion-color-cheval-horse_banner": "#fff",
    "--ion-background-cheval-horse_banner": "#e61780",
    "--ion-color-cheval-horse_number": "#cb14269c",
    "--ion-color-cheval-horse-details": "#fff",
    "--ion-color-cheval-horse-details-labels": "#505050",
    "--ion-color-cheval-horse-details-values": "#505050",
    // reunion page
    "--ion-color-reunion-date": "#505050",
    "--ion-color-reunion-name": "#505050",
    "--ion-background-reunion-name": "#888484de",

    // account page
    "--ion-background-acc-card": "#8a8a8ad9",
    "--ion-color-acc-card": "#fff",
    "--ion-color-acc-card-icon": "#fff",
    "--ion-color-acc-acc-header": "#fff",
    "--ion-background-acc-header": "#e61780",
    "--ion-color-account-header": "#7fc41c",
    // registration page
    "--ion-color-reg-icon-up": "#fff",
    "--icon-color-reg-icon-next": "#7fc41c",
    "--icon-background-reg-next": "#ef238e",
    "--icon-background-reg-header": "#7fc41c",
    "--icon-color-reg-header": "#fff",
    "--icon-color-reg-main-header": "#505050",
    // Races page
    "--icon-color-race_reun_num": "#7fc41c",
    // "--icon-background-depart": "#888484de",
    "--ion-color-text-shadow": "none",
    // account
    "--ion-color-confirm-transaction": "#fff",
    "--ion-background-confirm-transaction": "#505050",
    // transaction page
    "--ion-color-icon-calendar": "#7fc41c",
    "--ion-color-calendar": "#5d5d5d",
    "--ion-background-calendar-btn": "#ef238e",

    // user profile page

    "ion-color-button-user-profile-edit":"",
    "ion-background-button-user-profile-edit":"",
    "ion-color-button-user-profile-edit-border":"",
    "ion-color-button-user-profile-val":"",
    "ion-background-button-user-profile-val":"",
    "ion-color-button-user-profile-border-val":"",


    // ion buttons
    "--ion-button-color-primary": "#fff",
    "--ion-button-background-primary": "#505050",
    "--ion-button-color-secondary": "#fff",
    "--ion-button-background-secondary": "#7fc41c",
    "--ion-button-color-monCompte": "#fff",
    "--ion-button-color-monCompte-border": "#fff",
    "--ion-button-background-monCompte": "#7fc41c",
    "--ion-button-navs-active": "#e61780",
    "--ion-button-navs-not-active": "#e61780",
    "--ion-button-navs-color-not-active": "#fff",
    "--ion-button-navs-color-active": "#3d3d3d",
    // "--ion-button-color-primary": "",
    // ion checkbox
    "--ion-color-checkbox-border": "#505050",
    "--ion-background-checkbox": "#fff",
    "--ion-color-checkbox-border-checked": "#7fc41c",
    "--ion-background-checkbox-checked": "#7fc41c",
    "--ion-color-checkbox-checkmark": "#fff",

    "--app-background-gray-tint": "#5d5d5d"

  },
  country: "GA"
}
export const pmuc: Theme = {
  name: 'pmuc',
  useGateway: false,
  properties: {
    "--ion-color-primary": "#e61780",
    //  homepage
    "--ion-color-activeheader": "#fff",
    "--ion-background-activeheader": "#e61780",
    "--ion-color-activeReunion": "#5d5d5d",
    "--ion-color-activeRace": "#7fc41c",
    "--ion-color-race-card-details": "#7d7c7c",
    "--ion-color-meetings-header": "#e61780",
    "--ion-background-race-card": "#fff",
    "--ion-background-home": "#eaeaea",
    "--ion-border-race-card": "#fff",
    "--ion-color-race-for-theday": "#626262",
    "--ion-color-finishheader": "#fff",
    "--ion-background-finishheader": "#aaaaaa",
    "--ion-color-monCompteBtn": "#5d5d5d",
    "--ion-color-monCompte-label": "#e61780",
    // rapport page
    "--ion-background-middlecomponent": "#fff",
    "--ion-background-arriveecomponent": "#fff",
    "--ion-color-rapport-footer": "#fff",
    "--ion-background-rapport-footer": "#000",

    // header 
    "--ion-color-header": "#fff",
    "--ion-background-header": "#fff",

    "--ion-color-account-icon": "#fff",
    "--ion-color-account-info": "#fff",
    // login page
    "--ion-background-image-url-login": "url('assets/img/lonaci_bgLogin.jpg')",
    "--ion-background-image-url-login-position": "50% -100px",
    "--ion-color-activate-acc": "#e61780",
    "--ion-color-forgot-pwd": "#7fc41c",
    "--ion-color-login-icon-floating": "#5d5d5d",
    "--ion-color-fg-pw-text": "#ef238e",
    "--ion-button-background-registration": "#ebebeb",
    "--ion-button-color-registration": "#ef238e",
    "--ion-button-color-registration-border": "#ef238e",
    "--ion-button-background-login": "#ef238e",
    "--ion-button-color-login": "#fff",
    "--ion-button-color-login-border": "#ef238e",
    "--ion-color-phone-login": "#5d5d5d",
    "--ion-color-login-label": "#4A4A4A",

    // cheval page
    "--ion-color-cheval-race": "#7fc41c",
    "--ion-color-pink-cheval-race": "#aa0078",
    "--ion-color-cheval-RC": "#5d5d5d",
    "--ion-color-cheval-time": "#505050",
    "--ion-color-cheval-horse_banner": "#fff",
    "--ion-background-cheval-horse_banner": "#e61780",
    "--ion-color-cheval-horse_number": "#cb14269c",
    "--ion-color-cheval-horse-details": "#fff",
    "--ion-color-cheval-horse-details-labels": "#505050",
    "--ion-color-cheval-horse-details-values": "#505050",
    // reunion page
    "--ion-color-reunion-date": "#505050",
    "--ion-color-reunion-name": "#505050",
    "--ion-background-reunion-name": "#888484de",

    // account page
    "--ion-background-acc-card": "#8a8a8ad9",
    "--ion-color-acc-card": "#fff",
    "--ion-color-acc-card-icon": "#fff",
    "--ion-color-acc-acc-header": "#fff",
    "--ion-background-acc-header": "#e61780",
    "--ion-color-account-header": "#7fc41c",
    // registration page
    "--ion-color-reg-icon-up": "#fff",
    "--icon-color-reg-icon-next": "#7fc41c",
    "--icon-background-reg-next": "#ef238e",
    "--icon-background-reg-header": "#7fc41c",
    "--icon-color-reg-header": "#fff",
    "--icon-color-reg-main-header": "#505050",
    // Races page
    "--icon-color-race_reun_num": "#7fc41c",
    // "--icon-background-depart": "#888484de",
    "--ion-color-text-shadow": "none",
    // account
    "--ion-color-confirm-transaction": "#fff",
    "--ion-background-confirm-transaction": "#505050",
    // transaction page
    "--ion-color-icon-calendar": "#7fc41c",
    "--ion-color-calendar": "#5d5d5d",
    "--ion-background-calendar-btn": "#ef238e",

    // user profile page

    "ion-color-button-user-profile-edit":"",
    "ion-background-button-user-profile-edit":"",
    "ion-color-button-user-profile-edit-border":"",
    "ion-color-button-user-profile-val":"",
    "ion-background-button-user-profile-val":"",
    "ion-color-button-user-profile-border-val":"",


    // ion buttons
    "--ion-button-color-primary": "#fff",
    "--ion-button-background-primary": "#505050",
    "--ion-button-color-secondary": "#fff",
    "--ion-button-background-secondary": "#7fc41c",
    "--ion-button-color-monCompte": "#fff",
    "--ion-button-color-monCompte-border": "#fff",
    "--ion-button-background-monCompte": "#7fc41c",
    "--ion-button-navs-active": "#e61780",
    "--ion-button-navs-not-active": "#e61780",
    "--ion-button-navs-color-not-active": "#fff",
    "--ion-button-navs-color-active": "#3d3d3d",
    // "--ion-button-color-primary": "",
    // ion checkbox
    "--ion-color-checkbox-border": "#505050",
    "--ion-background-checkbox": "#fff",
    "--ion-color-checkbox-border-checked": "#7fc41c",
    "--ion-background-checkbox-checked": "#7fc41c",
    "--ion-color-checkbox-checkmark": "#fff",

    "--ion-color-heading-tint": "#6B6B6B",

    "--app-background-gray-tint": "#5d5d5d"

  },
  country: "CMR"
}

export const tunisia: Theme = {
  name: 'tunisia',
  useGateway: false,
  properties: {
    "--ion-color-primary": "#e61780",
    //  homepage
    "--ion-color-activeheader": "#fff",
    "--ion-background-activeheader": "#e61780",
    "--ion-color-activeReunion": "#5d5d5d",
    "--ion-color-activeRace": "#7fc41c",
    "--ion-color-race-card-details": "#7d7c7c",
    "--ion-color-meetings-header": "#e61780",
    "--ion-background-race-card": "#fff",
    "--ion-background-home": "#eaeaea",
    "--ion-border-race-card": "#fff",
    "--ion-color-race-for-theday": "#626262",
    "--ion-color-finishheader": "#fff",
    "--ion-background-finishheader": "#aaaaaa",
    "--ion-color-monCompteBtn": "#5d5d5d",
    "--ion-color-monCompte-label": "#e61780",
    // rapport page
    "--ion-background-middlecomponent": "#fff",
    "--ion-background-arriveecomponent": "#fff",
    "--ion-color-rapport-footer": "#fff",
    "--ion-background-rapport-footer": "#000",

    // header 
    "--ion-color-header": "#fff",
    "--ion-background-header": "#fff",

    "--ion-color-account-icon": "#fff",
    "--ion-color-account-info": "#fff",
    // login page
    "--ion-background-image-url-login": "url('assets/img/lonaci_bgLogin.jpg')",
    "--ion-background-image-url-login-position": "50% -100px",
    "--ion-color-activate-acc": "#e61780",
    "--ion-color-forgot-pwd": "#7fc41c",
    "--ion-color-login-icon-floating": "#5d5d5d",
    "--ion-color-fg-pw-text": "#ef238e",
    "--ion-button-background-registration": "#ebebeb",
    "--ion-button-color-registration": "#ef238e",
    "--ion-button-color-registration-border": "#ef238e",
    "--ion-button-background-login": "#ef238e",
    "--ion-button-color-login": "#fff",
    "--ion-button-color-login-border": "#ef238e",
    "--ion-color-phone-login": "#5d5d5d",
    "--ion-color-login-label": "#4A4A4A",

    // cheval page
    "--ion-color-cheval-race": "#7fc41c",
    "--ion-color-pink-cheval-race": "#aa0078",
    "--ion-color-cheval-RC": "#5d5d5d",
    "--ion-color-cheval-time": "#505050",
    "--ion-color-cheval-horse_banner": "#fff",
    "--ion-background-cheval-horse_banner": "#e61780",
    "--ion-color-cheval-horse_number": "#cb14269c",
    "--ion-color-cheval-horse-details": "#fff",
    "--ion-color-cheval-horse-details-labels": "#505050",
    "--ion-color-cheval-horse-details-values": "#505050",
    // reunion page
    "--ion-color-reunion-date": "#505050",
    "--ion-color-reunion-name": "#505050",
    "--ion-background-reunion-name": "#888484de",

    // account page
    "--ion-background-acc-card": "#8a8a8ad9",
    "--ion-color-acc-card": "#fff",
    "--ion-color-acc-card-icon": "#fff",
    "--ion-color-acc-acc-header": "#fff",
    "--ion-background-acc-header": "#e61780",
    "--ion-color-account-header": "#7fc41c",
    // registration page
    "--ion-color-reg-icon-up": "#fff",
    "--icon-color-reg-icon-next": "#7fc41c",
    "--icon-background-reg-next": "#ef238e",
    "--icon-background-reg-header": "#7fc41c",
    "--icon-color-reg-header": "#fff",
    "--icon-color-reg-main-header": "#505050",
    // Races page
    "--icon-color-race_reun_num": "#7fc41c",
    // "--icon-background-depart": "#888484de",
    "--ion-color-text-shadow": "none",
    // account
    "--ion-color-confirm-transaction": "#fff",
    "--ion-background-confirm-transaction": "#505050",
    // transaction page
    "--ion-color-icon-calendar": "#7fc41c",
    "--ion-color-calendar": "#5d5d5d",
    "--ion-background-calendar-btn": "#ef238e",

    // user profile page

    "ion-color-button-user-profile-edit":"",
    "ion-background-button-user-profile-edit":"",
    "ion-color-button-user-profile-edit-border":"",
    "ion-color-button-user-profile-val":"",
    "ion-background-button-user-profile-val":"",
    "ion-color-button-user-profile-border-val":"",

    "--ion-color-heading-tint": "#6B6B6B",

    // ion buttons
    "--ion-button-color-primary": "#fff",
    "--ion-button-background-primary": "#505050",
    "--ion-button-color-secondary": "#fff",
    "--ion-button-background-secondary": "#7fc41c",
    "--ion-button-color-monCompte": "#fff",
    "--ion-button-color-monCompte-border": "#fff",
    "--ion-button-background-monCompte": "#7fc41c",
    "--ion-button-navs-active": "#e61780",
    "--ion-button-navs-not-active": "#e61780",
    "--ion-button-navs-color-not-active": "#fff",
    "--ion-button-navs-color-active": "#3d3d3d",
    // "--ion-button-color-primary": "",
    // ion checkbox
    "--ion-color-checkbox-border": "#505050",
    "--ion-background-checkbox": "#fff",
    "--ion-color-checkbox-border-checked": "#7fc41c",
    "--ion-background-checkbox-checked": "#7fc41c",
    "--ion-color-checkbox-checkmark": "#fff",

    "--app-background-gray-tint": "#5d5d5d"

  },
  country: "TU"
}
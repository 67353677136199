import { Injectable } from '@angular/core';
import { SharedDataService } from './shared-data.service';
import { Http } from '../wrappers/http.service';
import { AlertController } from '@ionic/angular';
import { ConfigService } from './config.service';

@Injectable()
export class RapportService {

  data: any;
  data1: any;
  status: boolean;
  courseList: Array<any>;
  constructor(private sharedData: SharedDataService, private configService: ConfigService,
              private http: Http, public alertCtrl: AlertController) {
  }

  loadRapport(rNumber, cNumber) {
      const URL = this.configService.ServiceUrls.raceRapport + '?cid=' + cNumber + '&rid=' + rNumber;
      return new Promise((resolve, reject) => {
          this.http.get(URL, {}).then(data => {
                  resolve(data);
              });
      });
  }
}

/* 
 * To change this template, choose Tools | Templates
 * and open the template in the editor.
 */

export let BET = {
    ERROR_CODE_SOLDE_INSUFFISANT: '1',
    ERROR_CODE_INCORRECT_PIN: '2',
    // ERROR_CODE_INCORRECT_PIN_1: '02',
    ERROR_CODE_NO_ACCOUNT: '3',
    ERROR_CODE_SUCCESS: '4',
    ERROR_CODE_WRONG_CODE :"02",
    MESSAGE_CODE_INCORRECT_PASSWORD :"108",
    ERROR_CODE_MERCHANT_UNKNOWN : "03",
    ERROR_INCORRECT_DATE: '5',
    ERROR_CODE_AGENT_INCORRECT_PIN: '6',
    ERROR_CODE_CLIENT_INCORRECT_PIN: '7',
    ERROR_CODE_INCORRECT_FORMAT: '8',
    ERROR_CODE_UNKNOWN: '9',
    ERROR_CODE_PARAMETER_MISSING: '10',
    MESSAGE_CODE_SOLDE_INSUFFISANT : "107",
    MESSAGE_CODE_NO_INFO: '11',
    MESSAGE_CODE_NO_RACE: '12',
    MESSAGE_CODE_AGENT_NON_EXISTANT: '13',
    MESSAGE_CODE_PARIEUR_NON_EXISTANT: '14',
    MESSAGE_CODE_COURSE_DU_JEU_NON_DISPONIBLE: '15',
    MESSAGE_CODE_LE_JEU_EST_DEJA_ANNULER: '16',
    ERROR_CODE_CANAL_INCONUE: '17',
    MESSAGE_CODE_REF_PARIS_INCORECT: '18',
    ERROR_CODE_PROCESS_DUPLIQUER: ' 19',
    ERROR_CODE_PAS_DE_PROCESS_EN_MEMOIRE: '20',
    ERROR_CODE_PROCESS_CREER: '21',
    ERROR_CODE_PROCESS_EN_MEMOIRE: '22',
    MESSAGE_CODE_PIN_DIFFRENTS: '23',
    ERROR_CODE_SUCCESS_CANCEL_BET: '24',
    ERROR_CODE_SUCCESS_CHANGE_PIN: '25',
    MESSAGE_CODE_AGENT_MATRICULE_INVALIDE: '26',
    MESSAGE_CODE_PAS_SOLDE_DISPONIBLE: '27',
    MESSAGE_CODE_FIXTURE_INDISPONIBLE: '28',
    MESSAGE_CODE_CHANEL_INCONUE: '29',
    MESSAGE_CODE_POOL_INDISPONIBLE: '30',
    MESSAGE_CODE_NOMBRE_PARAMETRE_INVALIDE: '31',
    MESSAGE_CODE_PAS_DE_FIXTURE_DISPONIBLE_DANS_LE_POOL: '32',
    // MESSAGE_CODE_COMBINAISON_EST_INVALIDE: '33',
    MESSAGE_CODE_SESSION_INVALIDE: '33',
    MESSAGE_CODE_PAS_DE_FIXTURE_DISPONIBLE_DANS_LA_LISTE: '34',
    MESSAGE_CODE_BAD_VERSION: '35',
    MESSAGE_CODE_LIMIT_EXCEED: '36',
    MESSAGE_CODE_LIMIT_BET_EXCEED: '37',
    MESSAGE_CODE_AGENT_SUSPENDED: '38',
    MESSAGE_CHANNEL_NOT_ALLOWED_IN_LIST: '39',
    MESSAGE_MONTANT_INCORRECT: '40',
    MESSAGE_DECAISSEMENT_SUCCES: '41',
    ERROR_CODE_SUCCESS_CANCEL_DECAISSEMENT: '42',
    ERROR_CODE_CANCEL_TRX_NOT_FOUND: '43',
    MESSAGE_CODE_PRODUCT_SALED_SUCCES: '44',
    MESSAGE_CODE_PRODUCT_EMPTY_IN_STORAGE: '45',
    ERROR_CODE_SUCCESS_CANCEL_VENTE_PRODUCT: '46',
    ERROR_CODE_INCORRECT_REFILL_CODE: '47',
    ERROR_CODE_JEUX_PAS_DISPONIBLE: '48',
    MESSAGE_CODE_VERSION_OBSELETTE: '50',
    MESSAGE_CODE_VERSION_ENCOURS: '51',
    MESSAGE_CODE_VERSION_AVANCE: '52',
    MESSAGE_CHANNEL_NOT_ALLOWED_IN_POOL: '55',
    MESSAGE_CHANNEL_NOT_ALLOWED_IN_SUPERCOTE: '56',
    MESSAGE_CODE_FIXTURE_ANNULER: '57',
    MESSAGE_CODE_FIXTURE_SUSPENDU: '58',
    MESSAGE_COMMERCIAL_SUSPENDU: '59',
    ERROR_CODE_PAYMENT_CANCEL_FOUND: '60',
    ERROR_CODE_SALE_CANCEL_FOUND: '61',
    MESSAGE_ZONE_NOT_ALLOCATED_TO_COMMERCIAL: '62',
    MESSAGE_CODE_COMMERCIAL_NON_EXISTANT: '63',

    MESSAGE_CODE_POOL_SUSPENDU: '64',
    MESSAGE_CODE_MAXIMUM_WINNINGS_EXCEED: '65',
    MESSAGE_CODE_VALIDATION_FAILED: '66',
    MESSAGE_CODE_PICK_ERROR: '67',
    MESSAGE_CODE_BET_NOT_ASSIGN: '68',
    MESSAGE_CODE_BET_EXECUTION_ERROR: '69',
    MESSAGE_CODE_GAME_LIST_NOT_AVAILABLE: '70',
    MESSAGE_CODE_NOT_MISSING_PARAMETER: '71',
    MESSAGE_CODE_NOT_MISSING_PARAMETER_VALUE: '72',
    MESSAGE_CODE_CHANNEL_NOT_AVAILABLE: '73',
    MESSAGE_CODE_NOT_AVAILABLE_RACE_NUMBER: '74',
    MESSAGE_CODE_MORE_THAN_ONE_RACE: '75',
    MESSAGE_CODE_INVALID_RACE_STATUS: '76',
    MESSAGE_CODE_EXPIRE_RACE_DATE: '77',
    MESSAGE_CODE_CHANNEL_NOT_AVAILABLE_FOR_RACE: '78',
    MESSAGE_CODE_UNVALID_GAME_TYPE: '79',
    MESSAGE_CODE_INVALID_TOUT_ORDRE_PARAM: '80',
    MESSAGE_BAD_COMPLEMENT: '81',
    MESSAGE_CODE_INVALID_COEFFICIENT_PARAM: '82',
    MESSAGE_CODE_INVALID_FLEXY_PARAM: '83',
    MESSAGE_CODE_INVALID_SPOT_PARAM: '84',
    MESSAGE_CODE_GAME_TYPE_NOT_ALLOW: '85',
    MESSAGE_CODE_NON_PARTANT_EXISTS: '86',
    MESSAGE_CODE_UNKNOW_GAME_FORMULA: '87',
    MESSAGE_CODE_FORMULA_NOT_ALLOW_FOR_GAME_TYPE: '88',
    ERROR_CODE_MORE_THAN_ONE_ACCOUNT: '89',
    ERROR_CODE_BET_TRANSACTION_FAIL: '90',
    ERROR_CODE_INVALID_HORSE_NUMBER: '91',
    ERROR_CODE_FORMULA_NOT_AVAILABLE_FOR_THIS_GAME: '100',
    ERROR_CODE_DUPLICATE_HORSE_NUMBER: '101',
    MESSAGE_CODE_COMPLEMENT_NON_PARTANT: '102',
    MESSAGE_CODE_REF_AGENT_INCORECT: '103',
    MESSAGE_CODE_MISSING_PARAMETER_VALUE: '104',
    MESSAGE_CODE_INVALID_ACCOUNT: '105',
    MESSAGE_CODE_YOU_MUST_CHANGE_YOUR_PIN: '106',
    ERROR_CODE_AGENT_INCONNU: '111',
    MESSAGE_CODE_MEGA_MIX_NOT_ALLOW_SIMPLE_BET: '113',
    MESSAGE_CODE_MEGA_MIX_NOT_VALID: "114",
    MESSAGE_CODE_RACE_IS_PAST: '115',
    MESSAGE_CODE_AMOUNT_IS_MAX: '116',
    MESSAGE_CODE_AMOUNT_IS_MIn: '117',
    MESSAGE_CODE_AGENT_DESACTIVE: '118',
    MESSAGE_CODE_AGENT_SUSPEND: '119',
    MESSAGE_CODE_RACE_HAS_STARTED: '120',
    MESSAGE_CODE_BET_IN_SAME_FIXTURE_IN_LISTS: '121',
    MESSAGE_CODE_PRODUCT_INVALID: '122',
    MESSAGE_CODE_AGENT_PRODUCT_NOT_ALLOWED: '123',
    MESSAGE_CODE_FIXTURE_SUSPENDED_IN_LIST: '124',
    MESSAGE_CODE_FIXTURE_CANCEL_IN_LIST: '125',
    MESSAGE_CODE_LIST_CANCEL: '126',
    MESSAGE_CODE_LIST_SUSPENDED: '127',
    MESSAGE_CODE_ACCOUNT_EMPTY: '130',
    MESSAGE_CODE_EXTERNAL_CONNEXION_ERROR: '139',
    MESSAGE_RACE_BET_NOT_ALLOW_FOR_THIS_RACE: "112",
    MESSAGE_PRODUCT_NOT_FOUND: '0202'

}
import { DataService } from "src/app/providers/data-service.service";
import { SharedDataService } from "src/app/providers/shared-data.service";
import { LoginDataService } from "src/app/providers/login-data.service";
import { AfterViewInit, Component } from "@angular/core";
import {
  Platform,
  ToastController,
  MenuController,
  NavController,
} from "@ionic/angular";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { TranslateService } from "@ngx-translate/core";
// import { Subscription } from 'rxjs';
import { Services } from "./providers/main.service";
import { Alerts } from "./wrappers/alerts.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ConfigService } from "./providers/config.service";
import { Title } from "@angular/platform-browser";
import { Http } from "./wrappers/http.service";
import { BASE_CLASS } from "./configs";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements AfterViewInit {
  // @ViewChild(NavController) nav: NavController;
  public alertPresented: any;
  // private onResumeSubscription: Subscription;
  // connectionStatus: { icon: string, title: string, component: any } = { icon: '', title: '', component: '' };
  servicesObject: Services;
  pages: Array<{ icon: string; title: string; component: any }>;
  backPressed: boolean;
  alertCtrl: Alerts;
  Date = new Date();
  id: any;
  url = window.location.href;
  s1: any = "";
  favIcon: HTMLLinkElement = document.querySelector("#appIcon");
  title = this.getSiteTitle();
  sliderItems: any = [];
  constructor(
    private router: Router,
    public nav: NavController,
    private loginService: LoginDataService,
    private statusBar: StatusBar,
    private route: ActivatedRoute,
    public Toast: ToastController,
    private platform: Platform,
    public shareData: SharedDataService,
    private serviceObject: LoginDataService,
    public menu: MenuController,
    private titleService: Title,
    private http: Http,
    private configService: ConfigService,
    private homeService: DataService,
    private translate: TranslateService
  ) {
    this.titleService.setTitle(this.title);
    //  translate: TranslateService,
    // this.router.events.subscribe((ev) => {
    //   this.navigationInterceptor(ev);
    // });

    this.initializeTheme();
    //if (this.shareData.theme.name == "missci")
    shareData.localStorage.next(localStorage.register);

    this.alertPresented = false;
    this.translate = this.shareData.translate;
    this.alertCtrl = this.shareData.alertCtrl;
    this.serviceObject = serviceObject;
    platform.ready().then(() => {
      statusBar.styleDefault();
      this.setupBackButtonBehavior();
      // this.registerBackButtonListener();
    });
    this.initializeApp();
    this.pages = this.shareData._pages;
    this.shareData._pages[0].component = "login";
    this.translateConfig();
  }

  // private navigationInterceptor(event): void {
  //   if (event instanceof NavigationEnd) {
  //     if (event.url.indexOf("/miss-ci")) {
  //       this.shareData.theme = missci;
  //     }
  //   }
  // }

  ngOnInit() {}
  ngOnDestroy() {
    // always unsubscribe your subscriptions to prevent leaks
    // this.onResumeSubscription.unsubscribe();
  }

  backButtonAction() {
    /* checks if modal is open */

    /* exits the app, since this is the main/first tab */
    // navigator['app'].exitApp();
    // navigator.app.exitApp();
    (navigator as any).app.exitApp();
    // this.navCtrl.navigateRoot('/AnotherPage');  <-- if you wanted to go to another page
  }

  initializeApp() {
    const context = this;
    const length = this.shareData._pages.length;
    this.serviceObject.refreshSession().then((response) => {
      if (response.erc && response.erc == 1) {
        this.shareData._userData = JSON.parse(response.data);
        // console.log(this.sharedata._userData)
        context.shareData._loggedIn = true;
        context.shareData._pages[0].icon = "person";
        context.shareData._pages[0].title = "ml_my_account";
        context.shareData._pages[length - 1].icon = "log-out";
        context.shareData._pages[length - 1].title = "ml_logout";
      }
    });
    this.platform.ready().then(() => {
      this.registerBackButtonListener();
    });

    this.loginService.loadSlides({ channel_id: "CAGNOTTE" }).then((res) => {
      this.sliderItems = res.slides;
      // this.images = tabimages;
    });
  }
  ionViewDidEnter() {
    history.pushState(null, null, "");
    // console.log(this.configService.countries);
  }

  showMenu() {
    if (this.shareData._loggedIn === true) {
      // console.log(my account')
      this.shareData._pages[0].component = "account";
    } else {
      this.shareData._pages[0].component = "login";
      this.shareData._pages.splice(9, 1);
    }
  }

  setupBackButtonBehavior() {
    let lastTimeBackPress = 0;
    const timePeriodToExit = 3000;
    // If on web version (browser)
    if (window.location.protocol !== "file:") {
      // Register browser back button action(s)
      window.onpopstate = (evt) => {
        // Close menu if open
        if (this.menu.isOpen()) {
          this.menu.close();
          return;
        }
        // Close any active modals or overlays

        const activeView = this.router.url;

        if (activeView != null && this.router.url != "") {
          // Double check to exit app
          if (new Date().getTime() - lastTimeBackPress < timePeriodToExit) {
            // navigator.app.exitApp(); // Exit from app
            (navigator as any).app.exitApp();
          } else {
            const toast = this.Toast.create({
              message: "Press back again to exit App?",
              duration: 3000,
              position: "bottom",
            });
            // toast.present();
            lastTimeBackPress = new Date().getTime();
          }
        } else {
          // go to previous page
          this.nav.pop();
        }
      };
      // Fake browser history on each view enter
      this.ionViewDidEnter();
    }
  }

  registerBackButtonListener() {
    let lastTimeBackPress = 0;
    const timePeriodToExit = 3000;
    this.nav = this.getNav();
    this.platform.backButton.subscribe(() => {
      // get current active page
      const activeView = this.router.url;

      if (activeView != null && this.router.url != "") {
        // Double check to exit app
        if (new Date().getTime() - lastTimeBackPress < timePeriodToExit) {
          // navigator.app.exitApp(); // Exit from app
          (navigator as any).app.exitApp();
        } else {
          const toast = this.Toast.create({
            message: "Press back again to exit App?",
            duration: 3000,
            position: "bottom",
          });
          // toast.present();
          lastTimeBackPress = new Date().getTime();
        }
      } else {
        // go to previous page
        this.nav.pop();
      }
    });
  }

  getNav() {
    return this.nav;
  }
  removeElement(message: any) {
    this.pages.splice(this.pages.indexOf(message), 1);
  }
  checkAppUpdate() {
    this.shareData.showLoader();

    this.serviceObject.checkappUpdate().then(
      (data) => {
        if (
          (data.hasOwnProperty("ok") && !data.ok) ||
          (data.hasOwnProperty("erc") && data.erc == "1")
        ) {
          this.alertCtrl.present(
            this.translate.instant("ml_update"),
            this.translate.instant("ml_app_latest_version")
          );
        } else {
          window.open(
            data.downloadLink,
            "_system",
            "location=yes,enableViewportScale=yes,hidden=no"
          );
        }

        this.shareData.dismissLoader(null);
      },
      (erc) => {
        // this.newFunction();
      }
    );
  }

  openPage(page) {
    // console.log(page);
    this.showMenu();
    this.shareData.menuloginbtn = true;
    this.menu.close();
    if (page.hasOwnProperty("id")) {
      if (page.id == "update") {
        // console.log(page)
        this.checkAppUpdate();
        return;
      }
      if (page.id == "logout") {
        if (!this.configService.useGateway) {
          this.shareData._loggedIn = false;
          let length = this.shareData._pages.length;
          this.shareData.userData = undefined;
          this.shareData.saveCache("userdata", undefined);
          this.shareData._pages[0].icon = "log-in";
          this.shareData._pages[0].title = "ml_login";
          this.shareData._pages[length - 1].icon = "";
          this.shareData._pages[length - 1].title = "";
        } else {
          this.serviceObject.logout().then((res) => {
            // console.log(res, this.sharedata.userData);
            this.shareData.userData = undefined;
            this.shareData.setCookie("session", "", this.configService.expiry);
            // this.sharedata.saveCache('userdata', undefined);
            this.shareData._loggedIn = false;
            this.shareData._pages[0].icon = "log-in";
            this.shareData._pages[0].title = "ml_login";
            // this.sharedata._pages[length - 1].icon = '';
            // this.sharedata._pages[length - 1].title = '';
            this.nav.navigateRoot("/home");
          });

          return;
        }
      }
    }
    this.shareData.loadding = 1;
    this.nav.navigateForward("/" + page.component);
    // this.nav.navigateForward('/page.component').then(
    //   response => {
    //     this.sharedata.loadding = 0;
    //     this.nav.pop();
    //   },
    //   error => {
    //   }
    // ).catch(exception => {
    // });
  }
  authSession(param) {
    const context = this;
    const length = this.shareData._pages.length;
    // let param = {session: id}
    this.loginService.authSession(param).then((response: any) => {
      // console.log(response)
      try {
        if (response.erc == "0") {
          context.shareData.setCookie(
            "session",
            response.data.session,
            this.configService.expiry
          );
          context.shareData.saveCache(
            "userdata",
            JSON.stringify(response.data)
          );
          context.shareData._userData = response.data;
          // console.log(context.sharedata._userData)
          context.shareData._loggedIn = true;
          context.shareData._pages[0].icon = "person";
          context.shareData._pages[0].title = "ml_my_account";
          context.shareData._pages[length - 1].icon = "log-out";
          context.shareData._pages[length - 1].title = "ml_logout";
          context.loginService.startIdleWatch(response.data);
          if (this.shareData.theme.useGateway) {
            this.loginService.connect_session();
          }
          // switch (context.sharedata.betValidationNavCtrl.from) {
          //   case 'betView':
          //     context.sharedata.loadding = 1
          //     context.navCtrl.pop();
          //     break;
          //   case 'cartView':
          //     context.sharedata.loadding = 1
          //     context.navCtrl.pop();
          //     break;
          //   default:
          //     context.navCtrl.navigateRoot('home')
          //     context.sharedata.loadding = 1;
          //     break;
          // }
        } else {
        }
      } catch (err) {
        context.shareData.showCustomAlert("Error", "Login Failed");
      }
    });
  }
  ngAfterViewInit() {
    this.loadConfigs();

    // this.configService.$config.subscribe(res => {
    let sid: any;
    if (this.configService.useGateway) {
      // this.loginService.connect();

      if (this.url.split("?").length > 1) {
        if (this.url.includes("session")) {
          this.id = this.url.split("=")[1];
          this.id = { session: this.id };
          // window.location.href = this.url.split('session')[0];
          this.router.navigate([], {
            queryParams: {
              session: null,
            },
            queryParamsHandling: "merge",
          });
        } else {
          this.id = undefined;
        }
      }
      if (this.shareData.checkCookie("session")) {
        sid = this.shareData.getCookie("session");
      }
      if (sid || this.id) {
        this.authSession(this.id ? this.id : { session: sid });
        return;
      }
    } else {
      this.shareData._pages = this.shareData._pages.filter((val) => {
        return val.component != "cagnote" && val.component != "promotions";
      });
      // this.pages = this.sharedata._pages;
      // if (this.sharedata.getCache('userdata')) {
      //   this.sharedata.userData = JSON.parse(this.sharedata.getCache('userdata'));
      //   const length = this.sharedata._pages.length;
      //   // debugger;
      //   this.sharedata._loggedIn = true;
      //   this.sharedata._pages[0].icon = 'person';
      //   this.sharedata._pages[0].title = 'ml_my_account';
      //   this.sharedata._pages[length - 1].icon = 'log-out';
      //   this.sharedata._pages[length - 1].title = 'ml_logout';
      // }
      this.pages = this.shareData._pages;
    }

    // });
  }
  getValue(configs: Array<any>, arg1: string): any {
    let valueToReturn;
    configs.forEach((config) => {
      if (config.key === arg1) {
        valueToReturn = config.value;
      }
    });
    return valueToReturn;
  }
  translateConfig() {
    let userLang = "fr";
    if (this.shareData.theme.name != "pmug") {
      userLang = navigator.language.split("-")[0];
    }
    // use navigator lang if available
    // userLang = 'fr';
    // console.log(userLang);
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("fr");
    this.translate.use(userLang);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
  }
  loadConfigs() {
    this.http
      .get_request(
        this.configService.GATEWAYS.userService + "/system/configs",
        {}
      )
      .then((response) => {
        if (response.hasOwnProperty("erc") && response.erc == "1") {
          this.configService.min_age =
            response.data && this.getValue(response.data, "INT_MIN_AGE")
              ? this.getValue(response.data, "INT_MIN_AGE")
              : 21;
          this.configService.quick_signup_enabled =
            response.data &&
            this.getValue(response.data, "B_ENABLE_QUICK_SIGNUP")
              ? this.getValue(response.data, "B_ENABLE_QUICK_SIGNUP")
              : 0;
          this.configService.countryCode = response.data
            ? this.getValue(response.data, "DEFAULT_COUNTRY_CODE").split('+')[1]
            : undefined;
          this.configService.currency =
            response.data &&
            this.getValue(response.data, "STR_DISPLAY_CURRENCY_CODE")
              ? this.getValue(response.data, "STR_DISPLAY_CURRENCY_CODE")
              : "";
          // this.configsService.site_name = response.data ? this.getValue(response.data, 'STR_SITE_NAME') : undefined;
          this.configService.phoneNumberLength =
            response.data && this.getValue(response.data, "PHONE_NUMBER_LENGTH")
              ? this.getValue(response.data, "PHONE_NUMBER_LENGTH")
              : "10";
          this.configService.login_required =
            response.data && this.getValue(response.data, "B_LOGIN_REQUIRED")
              ? this.getValue(response.data, "B_LOGIN_REQUIRED")
              : 0;
          this.configService.STR_MISS_CI_PIN =
            response.data && this.getValue(response.data, "STR_MISS_CI_PIN")
              ? this.getValue(response.data, "STR_MISS_CI_PIN")
              : 1357;
          this.configService.usePromoCode =
            response.data &&
            this.getValue(response.data, "USE_PROMO_CODE_REGISTRATION")
              ? this.getValue(response.data, "USE_PROMO_CODE_REGISTRATION")
              : 0;
          this.configService.age_verification_required =
            response.data &&
            this.getValue(response.data, "AGE_VERIFICATION_REQUIRED")
              ? this.getValue(response.data, "AGE_VERIFICATION_REQUIRED")
              : 0;
        }
        // console.log(this.storage.get('isUserAgeVerified'))
        if (
          this.configService.age_verification_required == 1 &&
          !this.shareData.getCache("isUserAgeVerified")
        ) {
          this.router.navigateByUrl("age-verification");
        }
      });
    return this.http
      .get_request(
        this.configService.GATEWAYS.userService +
          this.configService.ServiceUrls.getConfigs,
        {}
      )
      .then((response) => {
        this.configService.tax = response.tax;
        // console.log("tax configured", this.configService.tax);
      })
      .catch((error) => {
        console.log("error response is ", error);
      });
  }
  getSiteTitle() {
    let title = this.translate.instant("bet_online");

    this.favIcon.href = "assets/lonacifavicon.png";
    this.s1 = "https://embed.tawk.to/5e90e2fb35bcbb0c9aafd865/default";
    this.shareData.siteTitle = "LONACI";
    title = "Paris en ligne | " + this.shareData.siteTitle;
    return title;
  }
  initializeTheme() {
    for (const property of Object.keys(this.shareData.theme.properties)) {
      document.documentElement.style.setProperty(
        property,
        this.shareData.theme.properties[property]
      );
    }
    this.configService.useGateway = this.shareData.theme.useGateway;
  }
}

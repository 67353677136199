import { Injectable } from '@angular/core';
import { Alerts } from '../wrappers/alerts.service';
import { APP_INFO } from '../configs';
import { Http } from '../wrappers/http.service';
import { SharedDataService } from './shared-data.service';
import { ConfigService } from './config.service';
@Injectable()
export class Services {

  data: any;
  data1: any;
  status: boolean;
  courseList: Array<any>;
  constructor(private sharedData: SharedDataService, private configService: ConfigService, private http: Http, public alertCtrl: Alerts) {
      //this.data = this.getHippiqueData(date);
  }


  getHippiqueData(date): Promise<any> {
      const params: any = {};
      params.date_jr = date !== null ? date : '';
      params.channel = 7;
      params.versioncode = 3;
      return this.http.get(this.configService.ServiceUrls.getHippiqueData, params);
  }

  saveBet(params): Promise<any> {
      var format = this.sharedData.CurrentgameData.icon + '-' +
       this.sharedData.CurrentgameData.touteOdre + '-' + 0 + '-' + this.sharedData.CurrentgameData.coefMax +
        '-' + this.sharedData.CurrentgameData.flexi + '-' + this.sharedData.isSpotGame + '-' + this.sharedData.horseSelectedArray;
      // + '?&channel=' + 7 + '&userid=' + params.userid + '&pwd=' + params.pwd + '&ref=' +
    //    params.ref + '&str_From=' + params.str_From + '&format=' + params.format + '&number=' + params.number;
      params.channel = 7;
      return this.http.get(this.configService.ServiceUrls.saveBet, params);

  }
  getTransactions(object): Promise<any> {
      // try {
        const params = object;
        params.lg_Customer_Account_ID = this.sharedData.userData.lg_AGENT_ID;
        params.transactiontype = 'typeTr';
        return this.http.get(this.configService.ServiceUrls.getTransactions, params);
  }
  checkappUpdate(): Promise<any> {
      const data: any = {};

      data.db_version_number = APP_INFO.appVersion;
      data.str_app_type = APP_INFO.package;
      return this.http.get(this.configService.ServiceUrls.app_update, data);

  }
  logout(): Promise<any> {
      return new Promise((resolve, reject) => {
          this.http.HPost(this.configService.GATEWAYS.userService +
             this.configService.ServiceUrls.logout, {sessionToken: this.sharedData.userData.session })
              .then(data => {
                this.sharedData._loggedIn = false;

                this.sharedData.userData = undefined;
                this.sharedData.setCookie('session', '', this.configService.expiry);
                // this.sharedData.saveCache('userdata', null);
                this.sharedData.showLoader();
                this.sharedData._pages[0].icon = 'log-in';
                this.sharedData._pages[0].title = 'ml_login';
                resolve(data);
              }, error => {
                  console.log(error);
                  resolve(error);
              });
      });
  }

  refreshSession(): Promise<any> {
      const sid = this.sharedData.getCookie('session');
    //   console.log('sid', sid)
      if (!sid)
          return new Promise((resolver, reject) => {
              reject('');
          });
      return new Promise((resolve, reject) => {

          this.http.post(this.configService.GATEWAYS.Production_serverAdress + this.configService.ServiceUrls.refreshUser, { 'sid': sid })
              .subscribe(data => {
                  resolve(data);
              }, error => {
                  console.log(error);
                  reject(error);
              });
      });
  }

  verifyAccnt(data): Promise<any> {
      return new Promise((resolve, reject) => {
          this.http.post(this.configService.GATEWAYS.Production_serverAdress + this.configService.ServiceUrls.verifyAccnt, data)
              .subscribe(data => {
                  resolve(data);
              }, error => {
                  console.log(error);
                  resolve(error);
              });
      });

  }
  confirmAccnt(data): Promise<any> {
      return new Promise((resolve, reject) => {
          this.http.post(this.configService.GATEWAYS.Production_serverAdress + this.configService.ServiceUrls.addAccnt, data)
              .subscribe(data => {
                  resolve(data);
              }, error => {
                  console.log(error);
                  resolve(error);
              });
      });

  }
}

import { Injectable } from "@angular/core";
import {
  ToastController,
  LoadingController,
  NavController,
} from "@ionic/angular";
import { Alerts } from "../wrappers/alerts.service";
import { Http } from "../wrappers/http.service";
import { TranslateService } from "@ngx-translate/core";
import { BET } from "../errorMessages";
import * as $ from "jquery";
import { BehaviorSubject } from "rxjs";
import { ConfigService } from "./config.service";
import { Theme, tunisia, lonagui, lonaci, pmug, pmuc, missci } from "../theme";

@Injectable()
export class SharedDataService {
  public pagetoshow: any = "1";
  showContent: boolean;
  private _theme: Theme = missci;
  // public agent_id;
  // public login;
  // public first_name;
  // public last_name;
  // public account_code;
  // public cni;
  // public email;
  private _siteTitle: String;
  public menuloginbtn = false;
  public personalDetails: any;
  public residence: any;
  public naissance: any;
  public _transactionLogs: any = [];
  public code: any;
  public validation: any;
  public loginParams: any;
  loadding: any = 0;
  public sex: any;
  public localStorage = new BehaviorSubject("");
  public _reunionName: any;
  // tslint:disable-next-line: variable-name
  public _reunionNumber: any;
  public _reunionCount: any;
  public _horses: any;
  public _rappotData: any;
  loader: any;
  amountTotal: any;
  public _pages =
    this.theme.name == "pmuc"
      ? [
          { icon: "log-in", title: "ml_login", component: {} },
          { icon: "home", title: "ml_home", component: "home" },
          { icon: "list", title: "ml_race_program", component: "reunion" },
          { icon: "cart", title: "gratuits", component: "gratuits" },
          { icon: "", title: "", component: {}, id: "logout" },
        ]
      : !this.theme.useGateway
      ? [
          { icon: "log-in", title: "ml_login", component: {} },
          { icon: "home", title: "ml_home", component: "home" },
          // { icon: 'list', title: 'ml_race_program', component: 'reunion' },
          // { icon: 'cart', title: 'gratuits', component: 'gratuits' },
          { icon: "help-circle", title: "faq", component: "faq" },
          // { icon: 'megaphone', title: 'ml_promotions', component: 'promotions' },
          // { icon: 'ribbon', title: 'ml_cagnote', component: 'cagnote' },
          // { icon: 'information-circle', title: 'avis-expert', component: 'avis-expert' },
          { icon: "play", title: "ml_how_to_play", component: "how-to" },
          { icon: "book", title: "lexiques", component: "lexiques" },
          { icon: "", title: "", component: {}, id: "logout" },
        ]
      : [
          // { icon: this.connectionStatus.icon, title: this.connectionStatus.title, component: this.connectionStatus.component },
          { icon: "log-in", title: "ml_login", component: {} },
          // { icon: 'home', title: 'ml_home', component: 'home' },
          { icon: "list", title: "ml_race_program", component: "reunion" },

          // { icon: 'cart', title: 'gratuits', component: 'gratuits' },
          { icon: "help-circle", title: "faq", component: "faq" },
          // { icon: 'megaphone', title: 'ml_promotions', component: 'promotions' },
          { icon: "ribbon", title: "ml_cagnote", component: "cagnote" },
          // { icon: 'information-circle', title: 'avis-expert', component: 'avis-expert' },
          // { icon: 'play', title: 'ml_how_to_play', component: 'how-to' },
          // { icon: 'book', title: 'lexiques', component: 'lexiques' },

          {
            icon: "refresh",
            title: "ml_check_update",
            component: {},
            id: "update",
          },
          { icon: "", title: "", component: {}, id: "logout" },
        ];
  public gamesToDisplay = [
    {
      name: "QNPC3",
      code: "",
      id: "1",
    },
    {
      name: "PQN",
      code: "",
      id: "1",
    },
    {
      name: "PQR",
      code: "",
      id: "3",
    },
    {
      name: "PP5",
      code: "",
      id: "1",
    },
    {
      name: "QNP",
      code: "",
      id: "2",
    },
    {
      name: "QRPC3",
      code: "",
      id: "3",
    },
    {
      name: "QRP",
      code: "",
      id: "4",
    },
    {
      name: "QNC3",
      code: "",
      id: "5",
    },
    {
      name: "QN",
      code: "",
      id: "6",
    },
    {
      name: "QRC3",
      code: "",
      id: "7",
    },
    {
      name: "QR",
      code: "",
      id: "8",
    },
    {
      name: "P5",
      code: "",
      id: "9",
    },
  ];

  checkIdletime = false;

  phoneNum_anonym: { num: string; valid: boolean };

  public savebetParam: {
    userid: string;
    pwd: string;
    format: string;
    number: number;
    channel: number;
    str_From: string;
    ref: string;
  };
  public _game: any;
  public _loggedIn: boolean;
  public _selectedHorse: any;
  public _userData: any;
  public _isSpotGame: boolean;
  public _gameArray: any;
  public _CartArray: any = [];
  public _SelectedBetsArray: any = [];
  public _courseObject: any;
  public _horse_selections: string;
  betValidationNavCtrl: { from: string } = { from: "" };
  public _horse_selectionsArray: any = [];
  // public currency : string = 'XOF'

  securityQuestions: any = [];
  _QuestionArray: any = [];
  paymentProviders: any = [];

  public _gameData: any;

  public _CurrentgameData: {
    icon: string;
    touteOdre: string;
    coefMax: string;
    flexi: string;
    horseSeletion: any;
  } = { icon: "", touteOdre: "", coefMax: "", flexi: "", horseSeletion: "" };

  public _HippiqueData: any;
  stompclient: any;
  loading: any;
  constructor(
    public translate: TranslateService,
    public alertCtrl: Alerts,
    private toastCtrl: ToastController,
    private http: Http,
    private loadingController: LoadingController,
    private navCtrl: NavController,
    private configService: ConfigService
  ) {
    this.phoneNum_anonym = { num: "", valid: false };

    // this.countries = this.configService.countries;
  }
  gameObject: {
    icon: string;
    prixdeBase: string;
    coefMax: string;
    numbreCheveauxBase: string;
    formuleDeJeur: string;
    color: string;
    touteOdre: string;
    flexi: string;
    spot: string;
    codeG: string;

  };
  get reunionName(): string {
    return this._reunionName;
  }
  get raceRapport() {
    return this._rappotData;
  }
  set raceRapport(value: any) {
    this._rappotData = value;
  }
  set transactionLogs(value: any) {
    this._transactionLogs = value;
  }

  get theme() {
    return this._theme;
  }
  set theme(theme: Theme) {
    this._theme = theme;
  }
  get siteTitle() {
    return this._siteTitle;
  }
  set siteTitle(siteTitle: String) {
    this._siteTitle = siteTitle;
  }
  get reunionCount() {
    return this._reunionCount;
  }

  set reunionCount(value) {
    this._reunionCount = value;
  }
  get transactionLogs() {
    return this._transactionLogs;
  }
  set isLoggedIn(vale: boolean) {
    this._loggedIn = vale;
  }
  get HippiqueData() {
    return this._HippiqueData;
  }
  set HippiqueData(value: any) {
    this._HippiqueData = value;
  }
  get LoggedIn(): boolean {
    return this._loggedIn;
  }
  get CurrentgameData(): any {
    return this._CurrentgameData;
  }
  set CurrentgameData(value: any) {
    this._CurrentgameData = value;
  }

  set QuestionArray(value: any) {
    this._QuestionArray = value;
  }
  get QuestionArray(): any {
    return this._QuestionArray;
  }

  get horses(): any {
    return this._horses;
  }
  get userData() {
    return this._userData;
  }
  set userData(value: any) {
    this._userData = value;
  }
  get reunionNumber(): any {
    return this._reunionNumber;
  }
  set reunionNumber(value: any) {
    this._reunionNumber = value;
  }
  AddtoPanier(value: Object): boolean {
    this._CartArray.push(value);
    return true;
  }
  get cartArray(): any {
    return this._CartArray;
  }
  get isSpotGame(): boolean {
    return this._isSpotGame;
  }
  get cartCount(): number {
    return this._CartArray.length;
  }
  get courseObject(): any {
    return this._courseObject;
  }
  get horseSelection(): string {
    return this._horse_selections;
  }
  get horseSelectedArray(): any {
    return this._horse_selectionsArray;
  }
  set horseSelectedArray(value: any) {
    this._horse_selectionsArray = value;
  }
  set reunionName(value: string) {
    this._reunionName = value;
  }
  removeBetSelection(bet: any) {
    this._SelectedBetsArray.slice(this._SelectedBetsArray.indexOf(bet), 1);
  }
  get SelectedBets() {
    return this._SelectedBetsArray;
  }
  // presentLoading() {
  //     this.loadding = 1
  // }
  initLoader() {
    this.loadding = 1;
    const loader = this.loadingController.create({
      //   dismissOnPageChange: true,
      duration: 3000,
      message: "please_wait",
    });
    return loader;
  }
  public mapUserData(data) {
    if (this.configService.useGateway) {
      return;
    }
    this._userData = {
      login: data.str_Login,
      email: data.str_Email,
      balances: {
        bon: [],
        total: data.dec_CUR_Balance,
        bonus: data.dec_bonus_balance,
        winnings: data.dec_WIN_Balance,
        dec_winning_cheque: data.dec_winning_cheque,
      },
      birthday: data.dt_AGENT_BIRTHDAY,
      first_name: data.str_Surname,
      last_name: data.str_Name,
      customer_ID: data.lg_AGENT_ID,
      lang: data.str_Lang,
      notif_Channels: "",
      phone_number: data.str_Phone,
      sex: data.str_Sexe,
      status: data.str_Statut,
      town: data.str_Town,
      account_code: data.str_Account_Code,
      pin: data.str_PIN,
      country: data.str_Lieu_Naissance,
      nationality: data.str_Nationalite,
      address: data.str_Address,
      password: data.str_Password,
      birthplace: data.str_Lieu_Naissance,
    };
  }
  getGratuits(param?): Promise<any> {
    return this.http.get_request(
      this.configService.GATEWAYS.sportcash +
        this.configService.ServiceUrls.gratuits,
      param
    );
  }
  // async presentLoading() {
  //     this.loading = await this.loadingController.create({
  //       message: 'Please wait...',
  //       duration: 3000
  //     // showBackdrop: true,
  //     });
  //     await this.loading.present();

  //     const { role, data } = await this.loading.onDidDismiss();

  //     console.log('Loading dismissed!');
  //   }
  async presentLoading() {
    // const alert = this.alertCtrl.present(this.translate.instant(ml_betHeader), this.translate.instant(ml_betMessage));
    this.loading = true;
    return await this.loadingController
      .create({
        // duration: 5000,
        message: this.translate.instant("please_wait"),
      })
      .then((a) => {
        a.present().then(() => {
          // console.log('presented');
          if (!this.loading) {
            a.dismiss();
            //   .then(() => console.log(this.translate.instant('abort presenting')));
          }
        });
      });
  }

  async dismiss() {
    //   console.log('dismissing')
    this.loading = false;
    return await this.loadingController.dismiss();
    // .then(() => console.log('dismissed'));
  }
  async presentLoadingWithOptions(message) {
    this.loading = await this.loadingController.create({
      spinner: null,
      duration: 5000,
      message: this.translate.instant(message),
      translucent: true,
      cssClass: "custom-class custom-loading",
    });
    return await this.loading.present();
  }
  async dismissLoader(loader?) {
    this.loadding = 0;
  }

  // async dismiss(){
  //     this.loading.dismiss();
  // }
  addtoSelectedArray(value: any) {
    if (
      value != "x" &&
      this.includesElement(this._horse_selectionsArray, value)
    ) {
      this.removeElement(value);
    } else {
      this._horse_selectionsArray.push(value);
    }
  }
  removeElement(message: any) {
    this._horse_selectionsArray.splice(
      this._horse_selectionsArray.indexOf(message),
      1
    );
  }
  set horses(value: any) {
    this._horses = value;
  }
  formatGameData(): any {
    this._gameArray = this.courseObject.jeu.split("@");
    for (let index = 0; index < this._gameArray.length; index++) {
      const gameInfo = this._gameArray[index];
      this.addGameData(gameInfo);
    }
  }
  get gameData(): any {
    return this._gameData;
  }
  addGameData(value: any) {
    this._gameData.push(value);
    this.populateGameObject();
  }

  getPaymentProviders() {
    return this.paymentProviders;
  }
  getSecurityQuestion(language: string): any {
    for (let i = 0; i < this.QuestionArray.length; i++) {
      if (language != "fr") {
        this.securityQuestions.push(
          this.QuestionArray[i]["question" + language.toUpperCase()]
        );
      } else {
        this.securityQuestions.push(this.QuestionArray[i].question);
      }
    }

    return this._QuestionArray;
  }
  populateGameObject() {
    const size = this.gameData.length;
    let gameInfo;
    for (let ind = 0; ind < size; ind++) {
      gameInfo = this.gameData[ind].split("-");
      this.gameObject = {
        icon: gameInfo[0],
        prixdeBase: gameInfo[1],
        coefMax: gameInfo[2],
        numbreCheveauxBase: gameInfo[3],
        formuleDeJeur: gameInfo[4].split(","),
        color: gameInfo[5],
        touteOdre: gameInfo[6],
        flexi: gameInfo[7],
        spot: gameInfo[8],
        codeG: gameInfo[9] || "0"
      };
    }
  }
  showLoader() {
    return this.initLoader();
  }
  showAlert() {
    const alert = this.alertCtrl.present("ml_error", "ml_fill_all_fields");
  }
  showconfirmDialoge(
    ml_header,
    ml_message,
    btn1,
    btn2,
    handler1,
    handler2,
    ...args
  ) {
    const alert = this.alertCtrl.present2Handler(
      ml_header,
      ml_message,
      btn1,
      btn2,
      handler1,
      handler2,
      args
    );
  }
  showconfirmDialoge2(
    ml_header,
    ml_message,
    btn1,
    btn2,
    handler1,
    handler2,
    ...args
  ) {
    const alert = this.alertCtrl.present2Handler(
      ml_header,
      ml_message,
      btn1,
      btn2,
      handler1,
      handler2,
      args
    );
  }
  showCustomAlert(ml_betHeader, ml_betMessage) {
    const alert = this.alertCtrl.present(
      this.translate.instant(ml_betHeader),
      this.translate.instant(ml_betMessage)
    );
  }
  toggle(event) {}
  getAmount( basePrice, coef, forumle, ordre: boolean, toutOrdre, k, p, n, b, s
  ): any {
    console.log(basePrice, coef, forumle, ordre, toutOrdre, k, p, n, b, s)
    const nbreCombinaison = this.combinaisonCount(
      forumle, ordre, toutOrdre, k, p, n, b, s );

    this.amountTotal = nbreCombinaison * basePrice * coef;
    this.amountTotal = this.amountTotal + "";
    return this.amountTotal;
  }
  arrangement(k, n): any {
    let r = 1;
    for (let i = 0; i < k; i++) {
      r *= n - i;
    }
    return r;
  }
  combinaison(k, n): any {
    let r = 1;
    for (let i = 0; i < k; i++) {
      // if (n != i)
      r = (r * (n - i)) / (i + 1);
    }
    // console.log(r)
    return r;
  }
  combinaisonCount(forumle, ordre, toutOrdre, k, p, n, b, s): any {
    if (!ordre) {
      // console.log('odre false')
      if (forumle == "CE") {
        return this.combinaison(s, k);
      }
      if (forumle == "CP") {
        return this.combinaison(s - b, p);
      }
      if (forumle == "CT") {
        return this.combinaison(s - b, n - b);
      }
    } else {
      if (!toutOrdre) {
        // console.log('odre true ttodre false')
        if (forumle == "CE") {
          return this.combinaison(s, k);
        }
        if (forumle == "CP") {
          return this.arrangement(s - b, p);
        }
        if (forumle == "CT") {
          return this.arrangement(s - b, n - b);
        }
      } else {
        // console.log('odre true ttodre true')
        if (forumle == "CE") {
          return this.arrangement(s, k);
        }
        if (forumle == "CP") {
          return this.arrangement(b, s) * this.arrangement(s - b, p);
        }
        if (forumle == "CT") {
          return this.arrangement(b, s) * this.arrangement(s - b, n - b);
        }
        if (forumle == "SI") {
          return this.arrangement(s, s);
        }
      }
    }
    return 1;
  }
  setseparator(location, amount): any {
    const index = location;
    const seperator = ",";
    const finalString = amount;
    const output = [
      amount.slice(0, location),
      seperator,
      amount.slice(location),
    ].join("");
    return output;
  }
  loadRapport(rNumber, cNumber) {
    const URL =
      this.configService.ServiceUrls.getRaceResults +
      "?cid=" +
      cNumber +
      "&rid=" +
      rNumber;
    return this.http.get(URL, {});
  }
  formatAmount(montant: any): any {
    if (montant === "0") {
      return "0";
    }
    if (!montant) {
      return false;
    }
    montant = montant + "";
    const size = montant.length;
    let toreturn = "";
    if (size < 4) {
      toreturn = montant;
    } else if (size == 4) {
      toreturn = this.setseparator(1, montant);
    } else if (size == 5) {
      toreturn = this.setseparator(2, montant);
    } else if (size == 6) {
      toreturn = this.setseparator(3, montant);
    } else if (size == 7) {
      toreturn = this.setseparator(5, this.setseparator(1, montant));
    } else if (size == 8) {
      toreturn = this.setseparator(6, this.setseparator(2, montant));
    } else if (size == 9) {
      toreturn = this.setseparator(7, this.setseparator(3, montant));
    }
    return toreturn;
  }
  buildhorseparams(rec, ordre, flexy, coef, id, vue, button) {
    const combinaison = this.horseSelectedArray;
    // if (rec.get('associe') != '0') {
    //     combinaison = rec.get('base') + '-' + rec.get('associe');
    // } else {
    //     combinaison = rec.get('base');
    // }
    const format =
      rec.get("libjeu") +
      "-" +
      ordre +
      "-" +
      0 +
      "-" +
      coef +
      "-" +
      flexy +
      "-" +
      rec.get("iSpot") +
      "-" +
      combinaison;
    let number = rec.get("idcourse");
    const phonenumber = 23765475;
    const pin = 5555;
    // var mat = accountstore.getAt(0).get('lg_AGENT_ID');
    //  params: {
    //     userid: userid,
    //     pwd: pwd + '@1',
    //     format: format.replace(/X/g, '*'),
    //     number: number,
    //     channel: '7',
    //     str_From: phone,
    //     ref: new Date().getTime() + '0' + parseInt(Math.random() * (10 - 1) + 1) + 'x' + parseInt(Math.random() * (10 - 1) + 1)
    // },
    //
    //       if(BET.app.getAppbuildType()==1){
    //    // if (Ext.os.is('Android')) {
    //         this.sendHorseURI(format, mat, pin, number, phonenumber, id.split('_')[0], vue, button);
    //     } else {
    //         this.sendHorseURI_(format, mat, pin, number, phonenumber, id.split('_')[0], vue, button);
    //     }
  }
  includesElement(array, elmt) {
    if (array.indexOf(elmt) != -1) {
      return true;
    } else {
      return false;
    }
  }

  async presentToast(message: any) {
    const toast = await this.toastCtrl.create({
      color: "dark",
      message,
      duration: 3000,
    });
    toast.present();
  }

  saveCartBets() {
    for (let i = 0; i < this.cartArray.length; i++) {
      this.CurrentgameData = this.cartArray[i];
      // this.SaveBet();
    }
  }
  getUserTransactions(day1, day2) {
    try {
      const obj = {
        id: this.userData.customer_ID,
        // id: this.userData.customer_ID,
        day: day1,
        day2,
      };
      this.getTransactions(obj);
    } catch (error) {}
  }
  clearCart() {
    while (this.cartCount > 0) {
      this._CartArray.pop();
    }
  }

  getTransactions(object) {
    return new Promise((resolve) => {
      this.http
        .get(
          this.configService.ServiceUrls.getTransactions +
            "?lg_Customer_Account_ID=" +
            this.userData.customer_ID +
            "&day=" +
            object.day +
            "&day2=" +
            object.day2 +
            "&transactiontype=typeTr",
          {}
        )
        .then(
          (data) => {
            try {
              document.getElementById("transactionSpinner").style.display =
                "none";
            } catch (err) {}
            this._transactionLogs = [];
            if (data == null) {
              return;
            } else {
              this._transactionLogs = JSON.parse(JSON.stringify(data));
              this.loadding = 0;
            }
          },
          (err) => {}
        );
    });
  }

  findErrorMessage(Code) {
    for (const key in BET) {
      if (BET[key] == Code) {
        return key;
      }
    }
    return "defaultFailed";
  }

  TogglePanel(item: any, id: string) {
    const mydiv = document.getElementById(id + item.numero);

    if (mydiv.style.display == "none") {
      mydiv.style.display = "block";
      // iconadd.style.display = "none";
      // iconremove.style.display = "block";
    } else {
      mydiv.style.display = "none";
      // iconadd.style.display = "block";
      // iconremove.style.display = "none";
    }
  }
  closeHorseDiv(item, id: string) {
    const mydiv = document.getElementById(id + item.numero);
    mydiv.style.display = "none";
  }
  formatDate(date: Date) {
    return (
      date.getFullYear() +
      "-" +
      (Number(date.getMonth()) + 1) +
      "-" +
      date.getDate()
    );
  }
  processJsonResponse(response: any) {
    this.loadding = 0;
    if (Array.isArray(response)) {
      response = response[0];
    }
    response = JSON.parse(JSON.stringify(response));
    // console.log(response)
    if (response !== null && response.erc !== "0") {
      if (response.ERROR_MESSAGE) {
        const alert = this.alertCtrl.present(
          this.translate.instant("ml_success"),
          response.ERROR_MESSAGE
        );
      }
    } else {
      // console.log(response["ERROR_MESSAGE"])
      const alert = this.alertCtrl.present(
        this.translate.instant("ml_error"),
        response == null
          ? this.translate.instant("ml_server_error")
          : this.translate.instant(
              isNaN(response.ERROR_MESSAGE)
                ? response.ERROR_MESSAGE
                : this.findErrorMessage(response.ERROR_MESSAGE)
            )
      );
    }
  }
  parseParams(data: any): any {
    const arr = Object.keys(data);
    let queryParams = "";
    for (let i = 0; i < arr.length; i++) {
      queryParams = queryParams + "&" + arr[i] + "=" + data[arr[i]];
    }
    return queryParams;
  }
  populateExtraDetails(data) {
    const contex = this;
    data = data.data;

    this.paymentProviders = [];
    if (data.AC) {
      this._QuestionArray = data.AC[1];
      data.AC[0].forEach((prov) => {
        if (prov.sub_providers && prov.sub_providers.length > 0) {
          prov.sub_providers.forEach((subProv) => {
            subProv.pvd = prov.code;
          });
          contex.paymentProviders.push(...prov.sub_providers);
        } else {
          contex.paymentProviders.push(prov);
        }
      });
    }
    // this.paymentProviders = data.AC[0];
    this.securityQuestions;
    // Code.secQuest = this.sharedData.getSecurityQuestion('fr');
  }
  populateExtraMissDetails(data) {
    const contex = this;
    data = data.data;

    this.paymentProviders = [];
    if (data.AC) {
      this._QuestionArray = data.AC[1];
    }
  }
  updateObject(oldObject: any, newObject: any) {
    const keys = Object.keys(oldObject);
    keys.forEach((element) => {
      oldObject[element] = newObject.hasOwnProperty(element)
        ? newObject[element]
        : oldObject[element];
    });
  }
  showProcessErrorResponse(data) {
    if (data.ok) {
      this.processJsonResponse(data);
    } else {
      this.alertCtrl.present(
        this.translate.instant("ml_error"),
        this.translate.instant("ml_server_error")
      );
    }
  }
  setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  checkCookie(cname) {
    let user = this.getCookie(cname);
    if (user != "") {
      return true;
    } else {
      return false;
    }
  }
  saveCache(key, value) {
    localStorage.setItem(key, value);
  }
  getCache(key) {
    return localStorage.getItem(key);
  }
  hidepage() {
    $('div[id^="section"]').hide();
    $("#section" + this.pagetoshow).show();
  }
  onClickItem(reunion: any, race: any) {
    this.loadding = 1;
    this._reunionName = reunion.libelle;
    this._reunionNumber = reunion.numero;
    this._reunionCount = reunion.nReunion;

    try {
      this._courseObject = race;
      this.navCtrl.navigateForward("cheval").catch((data) => {});
      return;

      // if (race.status == 0) {
      // }
      // else {
      //     this.navCtrl.navigateForward('/rapport');
      // }
    } catch (error) {
      console.log(error);
    }
  }
  getGamesToShow(race) {
    if (!race) {
      return;
    }
    if (!race.jeu) {
      return;
    }
    const games = [];
    const gms = race.jeu.split("@");
    gms.forEach((element) => {
      games.push(element.split(".")[0]);
    });
    const gmtoShow = games.filter((value) => {
      return race.jeu.indexOf(value.name) >= 0;
    });
    const gamecodes = [];
    games.forEach((element) => {
      gamecodes.unshift(element.split("-")[0]);
    });
    let idx = this.gamesToDisplay.filter((game) => {
      return gamecodes.indexOf(game.name) >= 0;
    });

    idx = this.sortArray(idx, "id");
    return idx[0] ? idx[0].name : undefined;
  }

  sortArray(arr, key) {
    arr.sort(function (a, b) {
      var keyA = new Date(a[key]),
        keyB = new Date(b[key]);
      // Compare the 2 keys
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return arr;
  }
}

import { Injectable } from '@angular/core';
import { Http } from '../wrappers/http.service';
import { AlertController } from '@ionic/angular';
import { ConfigService } from './config.service';

@Injectable()
export class RaceService {

  data: any;
    data1: any;
    status: boolean;
    courseList: Array<any>;
    constructor(private configService: ConfigService, private http: Http, public alertCtrl: AlertController) {
        //this.data = this.getHippiqueData(date);
    }

    loadRapport(rNumber, cNumber) {
        let URL = this.configService.ServiceUrls.getRaceResults + "?cid=" + cNumber + "&rid=" + rNumber
        return new Promise((resolve, reject) => {
            this.http.get(URL,{}).then(data => {
                    resolve(data)
                });
        });
    }

}
